import request from '../request'

//报销申请列表
export const reimbursement_index_request = p => {
    return request({
        method:'GET',
        url:'reimbursement/index',
        params: p
    })
}

//发起报销申请
export const reimbursement_add_request = d => {
    return request({
        method:'POST',
        url:'reimbursement/add',
        data: d
    })
}

//删除报销申请
export const reimbursement_del_request = id => {
    return request({
        method:'DELETE',
        url:'reimbursement/del',
        data: {
            id: id
        }
    })
}

//修改报销申请
export const reimbursement_edit_request = data => {
    return request({
        method:'PUT',
        url:'reimbursement/edit',
        data
    })
}

//获取报销申请详情
export const reimbursement_details_request = id => {
    return request({
        method:'GET',
        url:'reimbursement/details',
        params: {
            id
        }
    })
}

// 提交报销申请
export const reimbursement_to_approval_request = d => {
    return request({
        method:'PUT',
        url:'reimbursement/to_approval',
        data: {
            id:d.id,
            note:d.note
        }
    })
}

// 查看报销申请审批记录
export const reimbursement_sel_approval_request = id => {
    return request({
        method:'GET',
        url:'reimbursement/sel_approval',
        params: {
            id
        }
    })
}

// 审批报销申请
export const reimbursement_approval_request = d => {
    return request({
        method:'PUT',
        url:'reimbursement/approval',
        data: {
            id: d.id,
            opinion: d.opinion,
            note: d.note,
            other: d.other,
            go_staff_id: d.go_staff_id
        }
    })
}

// 报销汇款
export const reimbursement_pay_request = id => {
    return request({
        method:'PUT',
        url:'reimbursement/pay',
        data: {
            id: id
        }
    })
}

// 报销批量汇款
export const reimbursement_select_pay_request = id => {
    return request({
        method:'PUT',
        url:'reimbursement/select_pay',
        data: {
            id: id
        }
    })
}

// 撤回报销购申请
export const reimbursement_withdraw_request = d => {
    return request({
        method:'PUT',
        url:'reimbursement/withdraw',
        data: {
            id: d.id
        }
    })
}

// 报销单关联打印
export const reimbursement_sel_print_with_request = id => {
    return request({
        method:'GET',
        url:'reimbursement/sel_print_with',
        params: {
            id
        }
    })
}

// 打印报销申请
export const reimbursement_print_request = id => {
    return request({
        method:'PUT',
        url:'reimbursement/print',
        data: {
            id
        }
    })
}

// 补充采购申请附件
export const reimbursement_add_file_request = data => {
    return request({
        method:'PUT',
        url:'reimbursement/add_file',
        data
    })
}