<template>
    <div>
        <el-dialog 
            :visible.sync="dialogFormVisible" 
            :append-to-body="true" 
            width="70%" 
            :center="true" top="2vh"
            :close-on-click-modal="false" 
            :before-close="() => closeEntertainDialog('EntertainEditForm')"
            v-loading.fullscreen.lock="login_loading">
            <template #title>
                <span style="font-weight: bold;">{{dialogFormTitle}}</span>
                <el-tag v-if="entertain.state===0" type="info" size="mini">待提交</el-tag>
                <el-tag v-if="entertain.state===1" size="mini">审批中</el-tag>
                <el-tag v-if="entertain.state===2" type="success" size="mini">已通过</el-tag>
                <el-tag v-if="entertain.state===3" type="danger" size="mini">已驳回</el-tag>
                <el-button v-if="(entertain.state>0) && ($_has(print_auth))" plain circle icon="el-icon-printer"
                    @click="clk_print()"></el-button>
            </template>
            <div>
                <el-form 
                    :model="entertain" 
                    :rules="rules" 
                    ref="EntertainEditForm" 
                    label-width="100px" 
                    size="small">
                    <div v-if="show_type">
                        <fieldset class="fieldsetc">
                            <legend class="legendc">基础信息</legend>
                            <el-form-item label="申请部门" prop="department_id">
                                <el-cascader v-model="entertain.department_id" :options="enterprise_department"
                                    :props="{ expandTrigger: 'hover' }" clearable style="width: 100%">
                                </el-cascader>
                            </el-form-item>
                            <el-form-item label="申请人" prop="staff_id">
                                <el-select v-model="entertain.staff_id" filterable clearable placeholder="选择申请人"
                                    class="staff_idc">
                                    <el-option v-for="item in AllStaff" :key="item.id" :label="item.name"
                                        :value="item.id">
                                    </el-option>
                                </el-select>
                            </el-form-item>
                        </fieldset>
                        <fieldset class="fieldsetc bottomc">
                            <legend class="legendc">招待信息</legend>
                            <el-form-item label="招待标题" prop="title">
                                <el-input v-model="entertain.title" clearable></el-input>
                            </el-form-item>
                            <el-form-item label="招待目的" prop="content">
                                <el-input v-model="entertain.content" clearable></el-input>
                            </el-form-item>
                            <el-row :gutter="10">
                                <el-col :span="12">
                                    <el-form-item label="招待日期" prop="entertain_date">
                                        <el-date-picker v-model="entertain.entertain_date" align="right" type="date"
                                            placeholder="选择日期" value-format="yyyy-MM-dd" class="entertain_datec">
                                        </el-date-picker>
                                    </el-form-item>
                                </el-col>
                                <el-col :span="12">
                                    <el-form-item label="招待客户" prop="client_id">
                                        <el-select v-model="entertain.client_id" filterable clearable placeholder="选择客户"
                                            style="width:100%">
                                            <el-option v-for="item in AllClient" :key="item.id" :label="item.name"
                                                :value="item.id">
                                            </el-option>
                                        </el-select>
                                    </el-form-item>
                                </el-col>
                            </el-row>
                            <el-form-item label="物资需求" prop="goods">
                                <el-input type="textarea" :rows="3" v-model="entertain.goods" placeholder="请填写需要公司提供的物资，如不需要公司提供物资，请填写无"></el-input>
                            </el-form-item>
                            <el-row :gutter="10">
                                <el-col :span="12">
                                    <el-form-item label="预计人数" prop="client_num">
                                        <el-input-number
                                            v-model="entertain.client_num"
                                            :min="1"
                                            :step="1"
                                            :precision="0"
                                            :step-strictly="true"
                                            size="small"
                                            class="input_number"
                                        ></el-input-number>
                                    </el-form-item>
                                </el-col>
                                <el-col :span="12">
                                    <el-form-item label="预计花费" prop="money">
                                        <el-input-number
                                            v-model="entertain.money"
                                            :min="0"
                                            :step="1"
                                            :precision="2"
                                            :step-strictly="false"
                                            size="small"
                                            class="input_number"
                                        ></el-input-number>
                                    </el-form-item>
                                </el-col>
                            </el-row>
                            <el-form-item label="备注" prop="note">
                                <el-input type="textarea" :rows="3" v-model="entertain.note"></el-input>
                            </el-form-item>
                        </fieldset>
                        <common-upload-component
                            ref="upload"
                            :ffileList="entertain.other"
                            upload_url="entertain/upload"
                        ></common-upload-component>
                    </div>
                    <div v-else>
                        <fieldset class="fieldsetc">
                            <legend class="legendc">基础信息</legend>
                            <el-descriptions class="margin-top" :column="2"
                                :labelStyle="{'width':'120px','text-align':'center'}"
                                :contentStyle="{'text-align': 'center'}" border>
                                <el-descriptions-item>
                                    <template slot="label">
                                        <span>申请部门</span>
                                    </template>
                                    <span>{{entertain.enterprise_name ? entertain.enterprise_name + '/' +
                                        entertain.department_name : ''}}</span>
                                </el-descriptions-item>
                                <el-descriptions-item>
                                    <template slot="label">
                                        <span>申请人</span>
                                    </template>
                                    <span>{{entertain.staff_name}}</span>
                                </el-descriptions-item>
                            </el-descriptions>
                        </fieldset>
                        <fieldset class="fieldsetc bottomc">
                            <legend class="legendc">招待信息</legend>
                            <el-descriptions class="margin-top" :column="2"
                                :labelStyle="{'width':'120px','text-align':'center'}"
                                :contentStyle="{'text-align': 'center'}" border>
                                <el-descriptions-item :span="2">
                                    <template slot="label">
                                        <span>招待标题</span>
                                    </template>
                                    <span>{{entertain.title}}</span>
                                </el-descriptions-item>
                                <el-descriptions-item :span="2"
                                    :contentStyle="{'text-align':'left','white-space':'pre-line'}">
                                    <template slot="label">
                                        <span>招待目的</span>
                                    </template>
                                    <span>{{entertain.content}}</span>
                                </el-descriptions-item>
                                <el-descriptions-item>
                                    <template slot="label">
                                        <span>招待日期</span>
                                    </template>
                                    <span>{{entertain.entertain_date}}</span>
                                </el-descriptions-item>
                                <el-descriptions-item >
                                    <template slot="label">
                                        <span>招待客户</span>
                                    </template>
                                    <span>{{entertain.client_name}}</span>
                                </el-descriptions-item>
                                <el-descriptions-item>
                                    <template slot="label">
                                        <span>预计人数</span>
                                    </template>
                                    <span>{{entertain.client_num | fomatFloatClientNum}}</span>
                                </el-descriptions-item>
                                <el-descriptions-item>
                                    <template slot="label">
                                        <span>预计花费</span>
                                    </template>
                                    <span>{{entertain.money | fomatFloatMoney}}</span>
                                </el-descriptions-item>
                                <el-descriptions-item :span="2"
                                    :contentStyle="{'text-align':'left','white-space':'pre-line'}">
                                    <template slot="label">
                                        <span>物资需求</span>
                                    </template>
                                    <span>{{entertain.goods}}</span>
                                </el-descriptions-item>
                                <el-descriptions-item :span="2"
                                    :contentStyle="{'text-align':'left','white-space':'pre-line'}">
                                    <template slot="label">
                                        <span>备注</span>
                                    </template>
                                    <span>{{entertain.note}}</span>
                                </el-descriptions-item>
                            </el-descriptions>
                        </fieldset>
                        <common-upload-component
                            v-show="entertain.other.length"
                            ref="upload"
                            :ffileList="entertain.other"
                            upload_url="entertain/upload"
                            :disabled="true"
                        ></common-upload-component>
                    </div>
                    <common-upload-component
                        v-show="AddFileDialogVisible"
                        ref="file_upload"
                        :ffileList="file_arr"
                        upload_url="entertain/upload"
                        title="补充附件"
                    ></common-upload-component>
                </el-form>
            </div>
            <common-budget-component
                v-if="budget_count.all_quota"
                :budget_obj="budget_count"
            ></common-budget-component>
            <div slot="footer" class="dialog-footer">
                <div v-if="show_type===0">
                    <div v-if="AddFileDialogVisible === false">
                        <el-button v-if="add_file_auth" type="success" plain @click="add_file()">补充附件</el-button>
                        <el-button v-if="to_approval_auth" type="success" plain @click="to_approval('EntertainEditForm')">提交</el-button>
                        <el-button v-if="sel_approval_auth" type="warning" plain @click="sel_approval()">查看审批</el-button>
                        <el-button v-if="withdraw_auth" type="danger" plain @click="entertainWithdraw('PurchaseEditForm')">审批撤回</el-button>
                        <el-button v-if="edit_auth" type="primary" plain @click="$emit('show_edit')">修改</el-button>
                        <el-button v-if="del_auth" type="danger" plain @click="entertainDel('EntertainEditForm')">删除</el-button>
                        <el-button plain @click="closeEntertainDialog('EntertainEditForm')">关闭</el-button>
                    </div>
                    <div v-else>
                        <el-button type="success" plain @click="submit_add_file()">确定</el-button>
                        <el-button plain @click="exit_add_file()">取消</el-button>
                    </div>
                </div>
                <div v-else-if="show_type===1">
                    <el-button type="success" plain @click="submitInsert('EntertainEditForm')">确定</el-button>
                    <el-button type="warning" plain @click="resetForm('EntertainEditForm')">重置</el-button>
                    <el-button plain @click="closeEntertainDialog('EntertainEditForm')">关闭</el-button>
                </div>
                <div v-else>
                    <el-button type="success" plain @click="submitEdit('EntertainEditForm')">确定修改</el-button>
                    <el-button plain @click="closeEntertainDialog('EntertainEditForm')">取消操作</el-button>
                </div>
                <common-approval-dialog :id="id" :ApprovalDialogVisible="ApprovalDialogVisible" :data="ApprovalData"
                    :current_examine_staff_id="entertain.current_examine_staff_id" :ApprovalAuth="ApprovalAuth"
                    :ApprovalRequest="EntertainApprovalRequest" @exitApprovalDialog="exitApprovalDialog"
                    @refresh="$emit('frefresh')">
                </common-approval-dialog>
                <entertain-print-component :show_print="show_print" :data="entertain" :ApprovalData="ApprovalData"
                    @exit_print="show_print = false">
                </entertain-print-component>
            </div>
        </el-dialog>
    </div>
</template>

<script>
import CommonUploadComponent from '@/components/common/CommonUploadComponent'
import CommonApprovalDialog from '@/components/common/CommonApprovalDialog'
import EntertainPrintComponent from './EntertainPrintComponent'
import CommonBudgetComponent from '@/components/common/CommonBudgetComponent'

import {
    entertain_add_request,
    entertain_del_request,
    entertain_edit_request,
    entertain_to_approval_request,
    entertain_sel_approval_request,
    entertain_approval_request,
    entertain_withdraw_request,
    entertain_add_file_request
} from '@/network/finance/entertain.js'
import { staff_list_request } from '@/network/list.js'
import { fomatFloat,thousandBitSeparator } from '@/assets/js/common.js'

export default {
    name: 'EntertainEditComponent',
    data() {
        return {
            login_loading: false,
            ApprovalDialogVisible: false,
            entertain: {
                title: '',
                content: '',
                goods: '',
                note: '',
                department_id: [this.$store.state.user.enterprise_id,this.$store.state.user.department_id],
                apply_date:'',
                client_id:'',
                staff_id: this.$store.state.user.id,
                entertain_client_num: '',
                money: '',
                other: [],
                print: []
            },
            rules: {
                title: [
                    {
                        required: true,
                        message: '请输入招待标题',
                        trigger: 'blur',
                    },
                ],
                content: [
                    {
                        required: true,
                        message: '请输招待目的',
                        trigger: 'blur',
                    },
                ],
                entertain_date: [
                    {
                        required: true,
                        message: '请选择招待日期',
                        trigger: 'blur',
                    },
                ],
                goods: [
                    {
                        required: true,
                        message: '请填写物资需求',
                        trigger: 'blur',
                    },
                ],
                entertain_rank: [
                    {
                        required: true,
                        message: '请输入招待级别',
                        trigger: 'blur',
                    },
                ],
                money: [
                    {
                        required: true,
                        message: '请输入预计花费',
                        trigger: 'blur',
                    },
                ],
                client_name: [
                    {
                        required: true,
                        message: '请输入招待客户',
                        trigger: 'blur',
                    },
                ],                
                client_num: [
                    {
                        required: true,
                        message: '请输入预计人数',
                        trigger: 'blur',
                    },
                ],
                department_id: [
                    {
                        required: true,
                        message: '请选择申请部门',
                        trigger: 'blur',
                    },
                ],
                staff_id: [
                    {
                        required: true,
                        message: '请选择申请人',
                        trigger: 'blur',
                    },
                ],
            },
            AllStaff: [],
            ApprovalData: [],
            EntertainApprovalRequest:entertain_approval_request,
            show_print: false,
            AddFileDialogVisible: false,
            //补充附件
            file_arr: []
        }
    },
    computed: {
        dialogFormTitle() {
            if(this.show_type === 0) {
                return this.entertain.name ? '招待单：' + this.entertain.name : '招待单详情'
            } else if (this.show_type === 1) {
                return '添加招待申请'
            } else {
                return '修改招待申请'
            }
        },
    },
    methods: {
        closeEntertainDialog(formName) {
            this.entertain = {
                title: '',
                content: '',
                goods: '',
                note: '',
                department_id: [this.$store.state.user.enterprise_id,this.$store.state.user.department_id],
                apply_date:'',
                client_id:'',
                staff_id: this.$store.state.user.id,
                entertain_client_num: '',
                money: '',
                other: [],
                print: []
            }
            this.$emit('exitEntertainDialog')
        },
        submitInsert(formName) {
            this.$refs[formName].validate((valid) => {
                if (!valid) {
                    return
                }
                this.entertainInsert(formName)
            })
        },
        entertainInsert(formName) {
            this.login_loading = true
            let d = this.entertain
            d.department_id = d.department_id[d.department_id.length - 1]
            d.other = this.$refs.upload.fileList
            entertain_add_request(d)
                .then((s) => {
                    this.login_loading = false
                    if (s.status === 0) {
                        this.$message.success('添加成功!')
                        this.$emit('exitEntertainDialog')
                        this.$emit('search')
                        this.$emit('details_row',s.result.id*1)
                    } else {
                        this.$message.error(s.msg)
                    }
                })
                .catch((err) => {
                    this.login_loading = false
                    this.$message.error(err)
                })
        },
        resetForm(formName) {
            this.entertain = {
                title: '',
                content: '',
                goods: '',
                note: '',
                department_id: [this.$store.state.user.enterprise_id,this.$store.state.user.department_id],
                apply_date:'',
                client_id:'',
                staff_id: this.$store.state.user.id,
                entertain_client_num: '',
                money: '',
                other: [],
                print: []
            }
        },
        submitEdit(formName) {
            this.$refs[formName].validate((valid) => {
                if (!valid) {
                    return
                }
                this.entertainEdit(formName)
            })
        },
        entertainEdit(formName) {
            this.login_loading = true
            this.entertain.id = this.id
            this.entertain.department_id = this.entertain.department_id[this.entertain.department_id.length - 1]
            this.entertain.other = this.$refs.upload.fileList
            entertain_edit_request(this.entertain)
                .then((s) => {
                    this.login_loading = false
                    if (s.status === 0) {
                        this.$message.success('修改成功!')
                        this.$emit('exitEntertainDialog')
                        this.$emit('search')
                        this.$emit('details_row',this.entertain.id)
                    } else {
                        this.$message.error(s.msg)
                    }
                })
                .catch((err) => {
                    this.login_loading = false
                    this.$message.error(err)
                })
        },
        entertainDel(formName) {
            this.$confirm(
                '是否确定删除？（一经删除，将无法再进行恢复，请谨慎操作！）',
                '温馨提示',
                {
                    confirmButtonText: '确定',
                    cancelButtonText: '取消',
                    type: 'warning',
                }
            )
                .then(() => {
                    entertain_del_request(this.id)
                        .then((s) => {
                            if (s.status === 0) {
                                this.$message.success('删除成功!')
                                this.$emit('search')
                            } else {
                                this.$message.error(s.msg)
                            }
                        })
                        .catch((err) => {
                            this.$message.error(err)
                        })
                        .finally(() => this.closeEntertainDialog(formName))
                })
                .catch((err) => {})
        },
        get_all_list() {
            staff_list_request({})
                .then((s) => {
                    this.AllStaff = s.result
                })
                .catch((err) => {
                    console.log(err)
                })
        },
        to_approval(formName) {
            this.$prompt('请填写申请原因', '提示', {
                    confirmButtonText: '确定',
                    cancelButtonText: '取消',
                }).then(({ value }) => {
                    this.login_loading = true
                    entertain_to_approval_request({id:this.id,note:value})
                        .then((s) => {
                            this.login_loading = false
                            if (s.status === 0) {
                                    this.$message.success('提交成功!')
                                    this.$emit('search')
                            } else {
                                this.$message.error(s.msg)
                            }
                        })
                        .catch((err) => {
                            this.login_loading = false
                            this.$message.error(err)
                        })
                        .finally(() => this.closeEntertainDialog(formName))
                }).catch(()=>{});
        },
        exitApprovalDialog() {
            this.ApprovalDialogVisible = false
        },
        sel_approval() {
            this.login_loading = true
            entertain_sel_approval_request(this.id)
                .then((s) => {
                    this.login_loading = false
                    if (s.status === 0) {
                        this.ApprovalData = s.result
                        this.ApprovalDialogVisible = true
                    } else {
                        this.$message.error(s.msg)
                    }
                })
                .catch((err) => {
                    this.login_loading = false
                    this.$message.error(err)
                })
        },
        entertainWithdraw() {
            this.$confirm(
                '是否确定撤回？（请谨慎操作）',
                '温馨提示',
                {
                    confirmButtonText: '确定',
                    cancelButtonText: '取消',
                    type: 'warning',
                }
            )
                .then(() => {
                    this.login_loading = true
                    entertain_withdraw_request({id:this.id})
                        .then((s) => {
                            this.login_loading = false
                            if (s.status === 0) {
                                this.$message.success('已撤回!')
                                this.$emit('frefresh')
                                this.$emit('search')
                            } else {
                                this.$message.error(s.msg)
                            }
                        })
                        .catch((err) => {
                            this.login_loading = false
                            this.$message.error(err)
                        })
                })
                .catch((err) => {})
        },
        clk_print() {
            this.login_loading = true
            entertain_sel_approval_request(this.id)
                .then((s) => {
                    this.login_loading = false
                    if (s.status === 0) {
                        this.ApprovalData = s.result
                    } else {
                        this.$message.error(s.msg)
                    }
                })
                .catch((err) => {
                    this.login_loading = false
                    this.$message.error(err)
                })
            this.show_print = true
        },
        add_file() {
            this.AddFileDialogVisible = true
        },
        exit_add_file() {
            this.AddFileDialogVisible = false
            this.file_arr = []
        },
        submit_add_file() {
            let data = {}
            this.file_arr = this.$refs.file_upload.fileList
            data['id'] = this.id
            data['file'] = this.file_arr
            entertain_add_file_request(data)
                .then((s) => {
                    this.login_loading = false
                    if (s.status === 0) {
                        this.$message.success('补充附件成功!')
                        this.$emit('frefresh')
                        this.AddFileDialogVisible = false
                        this.file_arr = []
                    } else {
                        this.$message.error(s.msg)
                    }
                })
                .catch((err) => {
                    this.login_loading = false
                    this.$message.error(err)
                })
        }
    },
    filters: {
        fomatFloatMoney(data) {
            return data ? thousandBitSeparator(fomatFloat(data)) + '元' : ''
        },
        fomatFloatClientNum(data) {
            return data ? data + '人' : ''
        }
    },
    props: {
        dialogFormVisible: Boolean,
        id: {
            type: Number,
            default() {
                return 0
            },
        },
        entertain_details_data: {
            type: Object,
            default() {
                return {}
            },
        },
        enterprise_department: {
            type: Array,
            default() {
                return []
            },
        },
        AllClient: {
            type: Array,
            default() {
                return []
            },
        },
        show_type: {
            type:Number,
            default() {
                return 0
            },
        },
        edit_auth: {
            type:Boolean,
            default() {
                return false
            }
        },
        del_auth: {
            type:Boolean,
            default() {
                return false
            }
        },
        add_file_auth: {
            type:Boolean,
            default() {
                return false
            }
        },
        to_approval_auth: {
            type:Boolean,
            default() {
                return false
            }
        },
        sel_approval_auth: {
            type:Boolean,
            default() {
                return false
            }
        },
        ApprovalAuth: {
            type:Boolean,
            default() {
                return false
            }
        },
        withdraw_auth: {
            type:Boolean,
            default() {
                return false
            }
        },
        print_auth: String,
        budget_count: {
            type: Object,
            default() {
                return {
                    all_quota: 0,
                    use_quota: 0,
                    per_quota: 0,
                    entertain_quota: 0,
                    travel_quota: 0,
                    other_quota: 0
                }
            }
        }
    },
    created() {
        this.get_all_list()
    },
    destoryed() {},
    mounted() {},
    updated() {},
    components: {
        CommonUploadComponent,
        CommonApprovalDialog,
        EntertainPrintComponent,
        CommonBudgetComponent
    },
    watch: {
        entertain_details_data(newVal) {
            this.entertain = newVal
            this.entertain.id = this.id
        },
    },
}
</script>

<style lang='less'>
.bottomc {
    margin-bottom: 10px;
}
.el-date-editor.el-input {
    width: 100%;
}
.input_number {
    width: 100%;
}
</style>