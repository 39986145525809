import request from '../request'

//备用金申请列表
export const petty_index_request = p => {
    return request({
        method:'GET',
        url:'petty/index',
        params: p
    })
}

//发起备用金申请
export const petty_add_request = d => {
    return request({
        method:'POST',
        url:'petty/add',
        data: d
    })
}

//删除备用金申请
export const petty_del_request = id => {
    return request({
        method:'DELETE',
        url:'petty/del',
        data: {
            id: id
        }
    })
}

//修改备用金申请
export const petty_edit_request = data => {
    return request({
        method:'PUT',
        url:'petty/edit',
        data
    })
}

//获取备用金申请详情
export const petty_details_request = id => {
    return request({
        method:'GET',
        url:'petty/details',
        params: {
            id
        }
    })
}

// 提交备用金申请
export const petty_to_approval_request = d => {
    return request({
        method:'PUT',
        url:'petty/to_approval',
        data: {
            id:d.id,
            note:d.note
        }
    })
}

// 查看备用金申请审批记录
export const petty_sel_approval_request = id => {
    return request({
        method:'GET',
        url:'petty/sel_approval',
        params: {
            id
        }
    })
}

// 审批备用金申请
export const petty_approval_request = d => {
    return request({
        method:'PUT',
        url:'petty/approval',
        data: {
            id: d.id,
            opinion: d.opinion,
            note: d.note,
            other: d.other,
            go_staff_id: d.go_staff_id
        }
    })
}

// 备用金汇款
export const petty_pay_request = id => {
    return request({
        method:'PUT',
        url:'petty/pay',
        data: {
            id: id
        }
    })
}

// 备用金批量汇款
export const petty_select_pay_request = id => {
    return request({
        method:'PUT',
        url:'petty/select_pay',
        data: {
            id: id
        }
    })
}

// 撤回备用金申请
export const petty_withdraw_request = d => {
    return request({
        method:'PUT',
        url:'petty/withdraw',
        data: {
            id: d.id
        }
    })
}

// 打印备用金申请
export const petty_print_request = id => {
    return request({
        method:'PUT',
        url:'petty/print',
        data: {
            id
        }
    })
}

// 补充备用金申请附件
export const petty_add_file_request = data => {
    return request({
        method:'PUT',
        url:'petty/add_file',
        data
    })
}