<template>
    <div>
        <el-dialog
            :visible.sync="dialogFormVisible"
            :append-to-body="true"
            width="70%"
            :center="true"
            top="6vh"
            :close-on-click-modal="false"
            :before-close="() => closePurchaseDialog('PurchaseEditForm')"
            v-loading.fullscreen.lock="login_loading"
        >
            <template #title>
                <span style="font-weight: bold;">{{dialogFormTitle}}</span>
                <el-tag v-if="purchase.state===0" type="info" size="mini">待提交</el-tag>
                <el-tag v-if="purchase.state===1" size="mini">审批中</el-tag>
                <el-tag v-if="purchase.state===2" type="success" size="mini">已通过</el-tag>
                <el-tag v-if="purchase.state===3" type="danger" size="mini">已驳回</el-tag>
                <el-button
                    v-if="(purchase.state>0) && ($_has(print_auth))"
                    plain
                    circle
                    icon="el-icon-printer"
                    @click="clk_print()"
                    ></el-button
                >
            </template>
            <div>
                <el-form
                    :model="purchase"
                    :rules="rules"
                    ref="PurchaseEditForm"
                    label-width="100px"
                    size="small"
                >
                    <div v-if="show_type">
                        <fieldset class="fieldsetc">
                            <legend class="legendc">基础信息</legend>
                            <el-row :gutter="10">
                                <el-col :span="12">
                                    <el-form-item label="申请部门" prop="department_id">
                                        <el-cascader
                                            v-model="purchase.department_id"
                                            :options="enterprise_department"
                                            :props="{ expandTrigger: 'hover' }"
                                            clearable
                                            style="width: 100%"
                                        >
                                        </el-cascader>
                                    </el-form-item>
                                </el-col>
                                <el-col :span="12">
                                    <el-form-item  label="申请人" prop="staff_id">
                                        <el-select
                                            v-model="purchase.staff_id"
                                            filterable
                                            clearable
                                            placeholder="选择申请人"
                                            class="staff_idc"
                                        >
                                            <el-option
                                                v-for="item in AllStaff"
                                                :key="item.id"
                                                :label="item.name"
                                                :value="item.id"
                                            >
                                            </el-option>
                                        </el-select>
                                    </el-form-item>
                                </el-col>
                            </el-row>
                        </fieldset>
                        <fieldset class="fieldsetc">
                            <legend class="legendc">采购信息</legend>
                            <el-form-item label="采购标题" prop="title">
                                <el-input v-model="purchase.title" clearable ></el-input>
                            </el-form-item>
                            <el-row :gutter="10">
                                <el-col :span="12">
                                    <el-form-item label="申请金额" prop="money">
                                        <el-input-number
                                            v-model="purchase.money"
                                            :min="0"
                                            :step="1"
                                            :precision="2"
                                            :step-strictly="false"
                                            size="small"
                                            class="input_number"
                                        ></el-input-number>
                                    </el-form-item>
                                </el-col>
                                <el-col :span="12">
                                    <el-form-item label="采购类型" prop="type">
                                        <el-select
                                            v-model="purchase.type"
                                            placeholder="选择采购类型"
                                            class="typec"
                                            filterable
                                            clearable
                                        >
                                            <el-option
                                                v-for="(v,i) in ['行政类','招待类','非行政及招待']"
                                                :key="i"
                                                :label="v"
                                                :value="i"
                                            ></el-option>
                                        </el-select>
                                    </el-form-item>
                                </el-col>
                            </el-row>
                            <el-form-item label="采购清单" prop="content">
                                <el-input type="textarea" :rows="5" v-model="purchase.content"></el-input>
                            </el-form-item>
                            <el-form-item label="采购原因" prop="cause">
                                <el-input type="textarea" :rows="3" v-model="purchase.cause"></el-input>
                            </el-form-item>
                        </fieldset>
                        <common-upload-component
                            ref="upload"
                            :ffileList="purchase.other"
                            upload_url="purchase/upload"
                        ></common-upload-component>
                    </div>
                    <div v-else>
                        <fieldset class="fieldsetc">
                            <legend class="legendc">基础信息</legend>
                            <el-descriptions
                                class="margin-top"
                                :column="2"
                                :labelStyle="{'width':'120px','text-align':'center'}"
                                :contentStyle="{'text-align': 'center'}"
                                border
                            >
                                <el-descriptions-item>
                                    <template slot="label">
                                        <span>申请部门</span>
                                    </template>
                                    <span>{{purchase.enterprise_name ? purchase.enterprise_name + '/' + purchase.department_name : ''}}</span>
                                </el-descriptions-item>
                                <el-descriptions-item>
                                    <template slot="label">
                                        <span>申请人</span>
                                    </template>
                                    <span>{{purchase.staff_name}}</span>
                                </el-descriptions-item>
                            </el-descriptions>
                        </fieldset>
                        <fieldset class="fieldsetc">
                            <legend class="legendc">采购信息</legend>
                            <el-descriptions
                                class="margin-top"
                                :column="2"
                                :labelStyle="{'width':'120px','text-align':'center'}"
                                :contentStyle="{'text-align': 'center'}"
                                border
                            >
                                <el-descriptions-item>
                                    <template slot="label">
                                        <span>采购类型</span>
                                    </template>
                                    <span>{{purchase.type_name}}</span>
                                </el-descriptions-item>
                                <el-descriptions-item>
                                    <template slot="label">
                                        <span>申请金额</span>
                                    </template>
                                    <span>{{purchase.money | fomatFloatMoney}}</span>
                                </el-descriptions-item>
                                <el-descriptions-item :span="2">
                                    <template slot="label">
                                        <span>采购标题</span>
                                    </template>
                                    <span>{{purchase.title}}</span>
                                </el-descriptions-item>
                                <el-descriptions-item :span="2" :contentStyle="{'text-align':'left','white-space':'pre-line'}">
                                    <template slot="label">
                                        <span>采购清单</span>
                                    </template>
                                    <span>{{purchase.content}}</span>
                                </el-descriptions-item>
                                <el-descriptions-item :span="2" :contentStyle="{'text-align':'left','white-space':'pre-line'}">
                                    <template slot="label">
                                        <span>采购原因</span>
                                    </template>
                                    <span>{{purchase.cause}}</span>
                                </el-descriptions-item>
                            </el-descriptions>
                        </fieldset>
                        <common-upload-component
                            v-show="purchase.other.length"
                            ref="upload"
                            :ffileList="purchase.other"
                            upload_url="purchase/upload"
                            :disabled="true"
                        ></common-upload-component>
                        <common-upload-component
                            v-show="AddFileDialogVisible"
                            ref="file_upload"
                            :ffileList="file_arr"
                            upload_url="purchase/upload"
                            title="补充附件"
                        ></common-upload-component>
                    </div>
                </el-form>
            </div>
            <common-budget-component
                v-if="budget_count.all_quota"
                :budget_obj="budget_count"
            ></common-budget-component>
            <div slot="footer" class="dialog-footer">
                <div v-if="show_type===0">
                    <div v-if="AddFileDialogVisible === false">
                        <el-button
                            v-if="add_file_auth"
                            type="success"
                            plain
                            @click="add_file()"
                            >补充附件</el-button
                        >
                        <el-button
                            v-if="to_approval_auth"
                            type="success"
                            plain
                            @click="to_approval('PurchaseEditForm')"
                            >提交</el-button
                        >
                        <el-button
                            v-if="sel_approval_auth"
                            type="warning"
                            plain
                            @click="sel_approval()"
                            >查看审批</el-button
                        >
                        <el-button
                            v-if="withdraw_auth"
                            type="danger"
                            plain
                            @click="purchaseWithdraw('PurchaseEditForm')"
                            >审批撤回</el-button
                        >
                        <el-button
                            v-if="edit_auth"
                            type="primary"
                            plain
                            @click="$emit('show_edit')"
                            >修改</el-button
                        >
                        <el-button
                            v-if="del_auth"
                            type="danger"
                            plain
                            @click="purchaseDel('PurchaseEditForm')"
                            >删除</el-button
                        >
                        <el-button
                            plain
                            @click="closePurchaseDialog('PurchaseEditForm')"
                            >关闭</el-button
                        >
                    </div>
                    <div v-else>
                        <el-button
                            type="success"
                            plain
                            @click="submit_add_file()"
                            >确定</el-button
                        >
                        <el-button
                            plain
                            @click="exit_add_file()"
                            >取消</el-button
                        >
                    </div>
                </div>
                <div v-else-if="show_type===1">
                    <el-button
                        type="success"
                        plain
                        @click="submitInsert('PurchaseEditForm')"
                        >确定</el-button
                    >
                    <el-button
                        type="warning"
                        plain
                        @click="resetForm('PurchaseEditForm')"
                        >重置</el-button
                    >
                    <el-button
                        plain
                        @click="closePurchaseDialog('PurchaseEditForm')"
                        >关闭</el-button
                    >
                </div>
                <div v-else>
                    <el-button
                        type="success"
                        plain
                        @click="submitEdit('PurchaseEditForm')"
                        >确定修改</el-button
                    >
                    <el-button
                        plain
                        @click="closePurchaseDialog('PurchaseEditForm')"
                        >取消操作</el-button
                    >
                </div>
                <common-approval-dialog
                    :id="id"
                    :ApprovalDialogVisible="ApprovalDialogVisible"
                    :data="ApprovalData"
                    :current_examine_staff_id="purchase.current_examine_staff_id"
                    :ApprovalAuth="ApprovalAuth"
                    :ApprovalRequest="PurchaseApprovalRequest"
                    @exitApprovalDialog="exitApprovalDialog"
                    @refresh="$emit('frefresh')"
                >
                </common-approval-dialog>
                <purchase-print-component
                    :show_print="show_print"
                    :data="purchase"
                    :ApprovalData="ApprovalData"
                    @exit_print="show_print = false"
                >
                </purchase-print-component>
            </div>
        </el-dialog>
    </div>
</template>

<script>
import CommonUploadComponent from '@/components/common/CommonUploadComponent'
import CommonApprovalDialog from '@/components/common/CommonApprovalDialog'
import PurchasePrintComponent from './PurchasePrintComponent'
import CommonBudgetComponent from '@/components/common/CommonBudgetComponent'

import {
    purchase_add_request,
    purchase_del_request,
    purchase_edit_request,
    purchase_to_approval_request,
    purchase_sel_approval_request,
    purchase_approval_request,
    purchase_withdraw_request,
    purchase_add_file_request
} from '@/network/finance/purchase.js'
import { staff_list_request } from '@/network/list.js'
import { fomatFloat,thousandBitSeparator } from '@/assets/js/common.js'

export default {
    name: 'PurchaseEditComponent',
    data() {
        return {
            login_loading: false,
            ApprovalDialogVisible: false,
            purchase: {
                title: '',
                type: '',
                content: '',
                cause: '',
                department_id: [this.$store.state.user.enterprise_id,this.$store.state.user.department_id],
                staff_id: this.$store.state.user.id,
                money: '',
                other: [],
                print: []
            },
            rules: {
                title: [
                    {
                        required: true,
                        message: '请输入采购标题',
                        trigger: 'blur',
                    },
                ],
                type: [
                    {
                        required: true,
                        message: '请选择采购类型',
                        trigger: 'blur',
                    },
                ],
                content: [
                    {
                        required: true,
                        message: '请填写采购清单',
                        trigger: 'blur',
                    },
                ],
                cause: [
                    {
                        required: true,
                        message: '请填写采购原因',
                        trigger: 'blur',
                    },
                ],
                money: [
                    {
                        required: true,
                        message: '请输入申请金额',
                        trigger: 'blur',
                    }
                ],
                department_id: [
                    {
                        required: true,
                        message: '请选择申请部门',
                        trigger: 'blur',
                    },
                ],
                staff_id: [
                    {
                        required: true,
                        message: '请选择申请人',
                        trigger: 'blur',
                    },
                ],
            },
            AllStaff: [],
            ApprovalData: [],
            PurchaseApprovalRequest:purchase_approval_request,
            show_print: false,
            AddFileDialogVisible: false,
            //补充附件
            file_arr: []
        }
    },
    computed: {
        dialogFormTitle() {
            if(this.show_type === 0) {
                return this.purchase.name ? '采购单：' + this.purchase.name : '采购单详情'
            } else if (this.show_type === 1) {
                return '添加采购申请'
            } else {
                return '修改采购申请'
            }
        },
    },
    methods: {
        closePurchaseDialog(formName) {
            this.purchase = {
                title: '',
                type: '',
                content: '',
                cause: '',
                department_id: [this.$store.state.user.enterprise_id,this.$store.state.user.department_id],
                staff_id: this.$store.state.user.id,
                money: '',
                other: [],
                print: []
            }
            this.$emit('exitPurchaseDialog')
        },
        submitInsert(formName) {
            this.$refs[formName].validate((valid) => {
                if (!valid) {
                    return
                }
                this.purchaseInsert(formName)
            })
        },
        purchaseInsert(formName) {
            this.login_loading = true
            let d = this.purchase
            d.department_id = d.department_id[d.department_id.length - 1]
            d.other = this.$refs.upload.fileList
            purchase_add_request(d)
                .then((s) => {
                    this.login_loading = false
                    if (s.status === 0) {
                        this.$message.success('添加成功!')
                        this.$emit('exitPurchaseDialog')
                        this.$emit('search')
                        this.$emit('details_row',s.result.id*1)
                    } else {
                        this.$message.error(s.msg)
                    }
                })
                .catch((err) => {
                    this.login_loading = false
                    this.$message.error(err)
                })
        },
        resetForm(formName) {
            this.$refs[formName].resetFields()
        },
        submitEdit(formName) {
            this.$refs[formName].validate((valid) => {
                if (!valid) {
                    return
                }
                this.purchaseEdit(formName)
            })
        },
        purchaseEdit(formName) {
            this.login_loading = true
            this.purchase.id = this.id
            this.purchase.department_id = this.purchase.department_id[this.purchase.department_id.length - 1]
            this.purchase.other = this.$refs.upload.fileList
            purchase_edit_request(this.purchase)
                .then((s) => {
                    this.login_loading = false
                    if (s.status === 0) {
                        this.$message.success('修改成功!')
                        this.$emit('exitPurchaseDialog')
                        this.$emit('search')
                        this.$emit('details_row',this.purchase.id)
                    } else {
                        this.$message.error(s.msg)
                    }
                })
                .catch((err) => {
                    this.login_loading = false
                    this.$message.error(err)
                })
        },
        purchaseDel(formName) {
            this.$confirm(
                '是否确定删除？（一经删除，将无法再进行恢复，请谨慎操作！）',
                '温馨提示',
                {
                    confirmButtonText: '确定',
                    cancelButtonText: '取消',
                    type: 'warning',
                }
            )
                .then(() => {
                    purchase_del_request(this.id)
                        .then((s) => {
                            if (s.status === 0) {
                                this.$message.success('删除成功!')
                                this.$emit('search')
                            } else {
                                this.$message.error(s.msg)
                            }
                        })
                        .catch((err) => {
                            this.$message.error(err)
                        })
                        .finally(() => this.closePurchaseDialog(formName))
                })
                .catch((err) => {})
        },
        get_all_list() {
            staff_list_request({})
                .then((s) => {
                    this.AllStaff = s.result
                })
                .catch((err) => {
                    console.log(err)
                })
        },
        to_approval(formName) {
            this.$prompt('请填写申请原因', '提示', {
                    confirmButtonText: '确定',
                    cancelButtonText: '取消',
                }).then(({ value }) => {
                    this.login_loading = true
                    purchase_to_approval_request({id:this.id,note:value})
                        .then((s) => {
                            this.login_loading = false
                            if (s.status === 0) {
                                    this.$message.success('提交成功!')
                                    this.$emit('search')
                            } else {
                                this.$message.error(s.msg)
                            }
                        })
                        .catch((err) => {
                            this.login_loading = false
                            this.$message.error(err)
                        })
                        .finally(() => this.closePurchaseDialog(formName))
                }).catch(()=>{});
        },
        exitApprovalDialog() {
            this.ApprovalDialogVisible = false
        },
        sel_approval() {
            this.login_loading = true
            purchase_sel_approval_request(this.id)
                .then((s) => {
                    this.login_loading = false
                    if (s.status === 0) {
                        this.ApprovalData = s.result
                        this.ApprovalDialogVisible = true
                    } else {
                        this.$message.error(s.msg)
                    }
                })
                .catch((err) => {
                    this.login_loading = false
                    this.$message.error(err)
                })
        },
        purchaseWithdraw() {
            this.$confirm(
                '是否确定撤回？（请谨慎操作）',
                '温馨提示',
                {
                    confirmButtonText: '确定',
                    cancelButtonText: '取消',
                    type: 'warning',
                }
            )
                .then(() => {
                    this.login_loading = true
                    purchase_withdraw_request({id:this.id})
                        .then((s) => {
                            this.login_loading = false
                            if (s.status === 0) {
                                this.$message.success('已撤回!')
                                this.$emit('frefresh')
                                this.$emit('search')
                            } else {
                                this.$message.error(s.msg)
                            }
                        })
                        .catch((err) => {
                            this.login_loading = false
                            this.$message.error(err)
                        })
                })
                .catch((err) => {})
        },
        clk_print() {
            this.login_loading = true
            purchase_sel_approval_request(this.id)
                .then((s) => {
                    this.login_loading = false
                    if (s.status === 0) {
                        this.ApprovalData = s.result
                    } else {
                        this.$message.error(s.msg)
                    }
                })
                .catch((err) => {
                    this.login_loading = false
                    this.$message.error(err)
                })
            this.show_print = true
        },
        add_file() {
            this.AddFileDialogVisible = true
        },
        exit_add_file() {
            this.AddFileDialogVisible = false
            this.file_arr = []
        },
        submit_add_file() {
            let data = {}
            this.file_arr = this.$refs.file_upload.fileList
            data['id'] = this.id
            data['file'] = this.file_arr
            purchase_add_file_request(data)
                .then((s) => {
                    this.login_loading = false
                    if (s.status === 0) {
                        this.$message.success('补充附件成功!')
                        this.$emit('frefresh')
                        this.AddFileDialogVisible = false
                        this.file_arr = []
                    } else {
                        this.$message.error(s.msg)
                    }
                })
                .catch((err) => {
                    this.login_loading = false
                    this.$message.error(err)
                })
        },
    },
    filters: {
        fomatFloatMoney(data) {
            return data ? thousandBitSeparator(fomatFloat(data)) + '元' : ''
        }
    },
    props: {
        dialogFormVisible: Boolean,
        id: {
            type: Number,
            default() {
                return 0
            },
        },
        purchase_details_data: {
            type: Object,
            default() {
                return {}
            },
        },
        enterprise_department: {
            type: Array,
            default() {
                return []
            },
        },
        show_type: {
            type:Number,
            default() {
                return 0
            },
        },
        edit_auth: {
            type:Boolean,
            default() {
                return false
            }
        },
        del_auth: {
            type:Boolean,
            default() {
                return false
            }
        },
        add_file_auth: {
            type:Boolean,
            default() {
                return false
            }
        },
        to_approval_auth: {
            type:Boolean,
            default() {
                return false
            }
        },
        sel_approval_auth: {
            type:Boolean,
            default() {
                return false
            }
        },
        ApprovalAuth: {
            type:Boolean,
            default() {
                return false
            }
        },
        withdraw_auth: {
            type:Boolean,
            default() {
                return false
            }
        },
        print_auth: String,
        budget_count: {
            type: Object,
            default() {
                return {
                    all_quota: 0,
                    use_quota: 0,
                    per_quota: 0,
                    entertain_quota: 0,
                    travel_quota: 0,
                    other_quota: 0
                }
            }
        }
    },
    created() {
        this.get_all_list()
    },
    destoryed() {},
    mounted() {},
    updated() {},
    components: {
        CommonUploadComponent,
        CommonApprovalDialog,
        PurchasePrintComponent,
        CommonBudgetComponent
    },
    watch: {
        purchase_details_data(newVal) {
            this.purchase = newVal
            this.purchase.id = this.id
        },
    },
}
</script>

<style>
.input_number,.staff_idc,.typec {
    width: 100%;
}
</style>