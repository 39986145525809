import request from '../request'

//招待申请列表
export const entertain_index_request = p => {
    return request({
        method:'GET',
        url:'entertain/index',
        params: p
    })
}

//发起招待申请
export const entertain_add_request = d => {
    return request({
        method:'POST',
        url:'entertain/add',
        data: d
    })
}

//删除招待申请
export const entertain_del_request = id => {
    return request({
        method:'DELETE',
        url:'entertain/del',
        data: {
            id: id
        }
    })
}

//修改招待申请
export const entertain_edit_request = data => {
    return request({
        method:'PUT',
        url:'entertain/edit',
        data
    })
}

//获取招待申请详情
export const entertain_details_request = id => {
    return request({
        method:'GET',
        url:'entertain/details',
        params: {
            id
        }
    })
}

// 提交招待申请
export const entertain_to_approval_request = d => {
    return request({
        method:'PUT',
        url:'entertain/to_approval',
        data: {
            id:d.id,
            note:d.note
        }
    })
}

// 查看招待申请审批记录
export const entertain_sel_approval_request = id => {
    return request({
        method:'GET',
        url:'entertain/sel_approval',
        params: {
            id
        }
    })
}

// 审批招待申请
export const entertain_approval_request = d => {
    return request({
        method:'PUT',
        url:'entertain/approval',
        data: {
            id: d.id,
            opinion: d.opinion,
            note: d.note,
            other: d.other,
            go_staff_id: d.go_staff_id
        }
    })
}

// 撤回招待申请
export const entertain_withdraw_request = d => {
    return request({
        method:'PUT',
        url:'entertain/withdraw',
        data: {
            id: d.id
        }
    })
}

// 打印招待申请
export const entertain_print_request = id => {
    return request({
        method:'PUT',
        url:'entertain/print',
        data: {
            id
        }
    })
}

// 补充招待申请附件
export const entertain_add_file_request = data => {
    return request({
        method:'PUT',
        url:'entertain/add_file',
        data
    })
}