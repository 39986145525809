<template>
    <div>
        <el-dialog
            :visible.sync="dialogFormVisible"
            :append-to-body="true"
            width="70%"
            :center="true"
            top="1vh"
            :close-on-click-modal="false"
            :before-close="() => closeDialog('ReimbursementEditForm')"
            v-loading.fullscreen.lock="login_loading"
        >
            <template #title>
                <span style="font-weight: bold;">{{dialogFormTitle}}</span>
                <el-tag v-if="reimbursement.state===0" type="info" size="mini">待提交</el-tag>
                <el-tag v-if="reimbursement.state===1" size="mini">审批中</el-tag>
                <el-tag v-if="reimbursement.state===2" type="success" size="mini">已通过</el-tag>
                <el-tag v-if="reimbursement.state===3" type="danger" size="mini">已驳回</el-tag>
                <el-button
                    v-if="(reimbursement.state>0) && ($_has(print_auth))"
                    plain
                    circle
                    icon="el-icon-printer"
                    @click="clk_print()"
                    ></el-button
                >
            </template>
            <div>
                <el-form
                    :model="reimbursement"
                    :rules="rules"
                    ref="ReimbursementEditForm"
                    label-width="100px"
                    size="small"
                >
                    <div v-if="show_type">
                        <fieldset  class="fieldsetc">
                            <legend class="legendc">基础信息</legend>
                            <el-form-item label="报销标题" prop="title">
                                <el-input v-model="reimbursement.title" clearable ></el-input>
                            </el-form-item>
                            <el-form-item label="申请部门" prop="department_id">
                                <el-cascader
                                    v-model="reimbursement.department_id"
                                    :options="enterprise_department"
                                    :props="{ expandTrigger: 'hover' }"
                                    clearable
                                    style="width: 100%"
                                >
                                </el-cascader>
                            </el-form-item>
                        </fieldset>
                        <fieldset  class="fieldsetc">
                            <legend class="legendc">关联信息</legend>
                            <el-form-item  label="采购申请" prop="with_purchase">
                                <el-select
                                    v-model="reimbursement.with_purchase"
                                    filterable
                                    clearable
                                    multiple
                                    :multiple-limit="10"
                                    placeholder="选择采购申请"
                                    class="with_purchasec"
                                >
                                    <el-option
                                        v-for="item in my_purchase"
                                        :key="item.name"
                                        :label="'【'+item.apply_date+'】' + '【'+item.staff_name+'】' + '【'+item.money+'元】' + item.title"
                                        :value="item.id"
                                    >
                                    </el-option>
                                </el-select>
                            </el-form-item>
                            <el-form-item  label="招待申请" prop="with_entertain">
                                <el-select
                                    v-model="reimbursement.with_entertain"
                                    filterable
                                    clearable
                                    multiple
                                    :multiple-limit="10"
                                    placeholder="选择招待申请"
                                    class="with_entertainc"
                                >
                                    <el-option
                                        v-for="item in my_entertain"
                                        :key="item.name"
                                        :label="'【'+item.apply_date+'】' + '【'+item.staff_name+'】' + '【'+item.money+'元】' + item.title"
                                        :value="item.id"
                                    >
                                    </el-option>
                                </el-select>
                            </el-form-item>
                            <el-form-item  label="差旅申请" prop="with_travel">
                                <el-select
                                    v-model="reimbursement.with_travel"
                                    filterable
                                    clearable
                                    multiple
                                    :multiple-limit="10"
                                    placeholder="选择差旅申请"
                                    class="with_travelc"
                                >
                                    <el-option
                                        v-for="item in my_travel"
                                        :key="item.name"
                                        :label="'【'+item.apply_date+'】' + '【'+item.staff_name+'】' + '【'+item.money+'元】' + item.title"
                                        :value="item.id"
                                    >
                                    </el-option>
                                </el-select>
                            </el-form-item>
                        </fieldset>
                        <fieldset  class="fieldsetc topc">
                            <legend class="legendc">报销信息</legend>
                            <el-row :gutter="10" style="margin-bottom:10px;">
                                <el-col :span="2" class="row_title">序号</el-col>
                                <el-col :span="6" class="row_title">报销类目</el-col>
                                <el-col :span="4" class="row_title">金额</el-col>
                                <el-col :span="8" class="row_title">备注</el-col>
                                <el-col :span="4" class="row_title"></el-col>
                            </el-row>
                            <el-row :gutter="10" v-for="(value,index) in reimbursement.with_category" :key="'报销类目' + index"  style="margin-bottom:10px;">
                                <el-col :span="2" style="text-align: center;">
                                        {{index + 1}}
                                </el-col>
                                <el-col :span="6">
                                    <el-select
                                        v-model="value.category_id"
                                        placeholder="选择报销类目"
                                        class="petty_idc"
                                        clearable
                                        filterable
                                        size="small"
                                    >
                                        <el-option
                                            v-for="item in AllCategory"
                                            :key="item.name + index"
                                            :label="item.name"
                                            :value="item.id"
                                        ></el-option>
                                    </el-select>
                                </el-col>
                                <el-col :span="4" class="row_content">
                                    <el-input-number
                                        v-model="value.money"
                                        :min="0"
                                        :step="1"
                                        :precision="2"
                                        :step-strictly="false"
                                        @change="apply_money"
                                        size="small"
                                        class="input_number"
                                    ></el-input-number>
                                </el-col>
                                <el-col :span="8">
                                    <el-input v-model="value.note" clearable size="small"></el-input>
                                </el-col>
                                <el-col :span="4">
                                    <el-button type="success" plain icon="el-icon-plus" circle size="small" @click="AddReimbursementCategory(reimbursement.with_category,value)"></el-button>
                                    <el-button type="danger" plain icon="el-icon-delete" circle size="small" v-if="index !==0" @click.prevent="DelReimbursementCategory(reimbursement.with_category,value)"></el-button>
                                </el-col>
                            </el-row>
                            <el-descriptions
                                class="margin-top"
                                :column="3"
                                :labelStyle="{'width':'120px','text-align':'center'}"
                                :contentStyle="{'text-align': 'center'}"
                                border
                            >
                                <el-descriptions-item :contentStyle="{'text-align':'center','color':'#2828FF'}">
                                    <template slot="label">
                                        <span>申请报销金额</span>
                                    </template>
                                    <span>{{reimbursement.apply_money | fomatFloatMoney}}</span>
                                </el-descriptions-item>
                                <el-descriptions-item :contentStyle="{'text-align':'center','color':'#FF0000'}">
                                    <template slot="label">
                                        <span>抵扣金额</span>
                                    </template>
                                    <span>{{this.deduction_money | deduction_money_filter}}</span>
                                </el-descriptions-item>
                                <el-descriptions-item :contentStyle="{'text-align':'center','color':'#00BB00'}">
                                    <template slot="label">
                                        <span>实际报销金额</span>
                                    </template>
                                    <span>{{this.reality_money | reality_money_filter}}</span>
                                </el-descriptions-item>
                            </el-descriptions>
                        </fieldset>
                        <fieldset class="fieldsetc">
                            <legend class="legendc">抵扣信息</legend>
                            <el-row :gutter="10" style="margin-bottom:10px;">
                                <el-col :span="2" class="row_title">序号</el-col>
                                <el-col :span="6" class="row_title">借支单</el-col>
                                <el-col :span="4" class="row_title">抵扣金额</el-col>
                                <el-col :span="8" class="row_title">备注</el-col>
                                <el-col :span="4" class="row_title"></el-col>
                            </el-row>
                            <el-row :gutter="10" v-for="(value,index) in reimbursement.with_petty" :key="'提前借支' + index"  style="margin-bottom:10px;">
                                <el-col :span="2" style="text-align: center;">
                                        {{index + 1}}
                                </el-col>
                                <el-col :span="6">
                                    <el-select
                                        v-model="value.petty_id"
                                        placeholder="选择借支单"
                                        class="petty_idc"
                                        clearable
                                        filterable
                                        size="small"
                                        @change="(e) => WithPettyChange(e,value)"
                                    >
                                        <el-option
                                            v-for="item in my_petty"
                                            :key="item.name + index"
                                            :label="'【'+item.surplus_money+'元】'+item.title"
                                            :value="item.id"
                                        ></el-option>
                                    </el-select>
                                </el-col>
                                <el-col :span="4" class="row_content">
                                    <el-input-number
                                            v-model="value.money"
                                            :min="0"
                                            :step="1"
                                            :precision="2"
                                            :step-strictly="false"
                                            size="small"
                                            class="input_number"
                                        ></el-input-number>
                                </el-col>
                                <el-col :span="8">
                                    <el-input v-model="value.note" clearable size="small"></el-input>
                                </el-col>
                                <el-col :span="4">
                                    <el-button type="success" plain icon="el-icon-plus" circle size="small" @click="AddReimbursementPetty(reimbursement.with_petty,value)"></el-button>
                                    <el-button type="danger" plain icon="el-icon-delete" circle size="small" v-if="index !==0" @click.prevent="DelReimbursementPetty(reimbursement.with_petty,value)"></el-button>
                                </el-col>
                            </el-row>
                        </fieldset>
                        <fieldset  class="fieldsetc bottomc">
                            <legend class="legendc">账户信息</legend>
                            <el-form-item  label="汇款方式" prop="pay_way">
                                <el-select
                                    v-model="reimbursement.pay_way"
                                    filterable
                                    clearable
                                    placeholder="选择汇款方式"
                                    class="pay_wayc"
                                >
                                    <el-option
                                        v-for="(v,i) in all_pay_way"
                                        :key="v"
                                        :label="v"
                                        :value="i"
                                    >
                                    </el-option>
                                </el-select>
                            </el-form-item>
                            <div>
                                <el-form-item  label="选择银行卡" prop="with_bank_card">
                                    <el-select
                                        v-model="reimbursement.with_bank_card"
                                        filterable
                                        clearable
                                        placeholder="选择银行卡"
                                        @change="(e) => cardChange(e)"
                                        class="with_bank_cardc"
                                    >
                                        <el-option
                                            v-for="item in Allcard"
                                            :key="item.card"
                                            :label="'【'+item.user_name+'】'+item.card+' — '+item.bank"
                                            :value="item.id"
                                        >
                                        </el-option>
                                    </el-select>
                                </el-form-item>
                                <el-form-item label="卡号">
                                    <span>{{reimbursement.account_card}}</span>
                                </el-form-item>
                                <el-form-item label="户主">
                                    <span>{{reimbursement.account_name}}</span>
                                </el-form-item>
                                <el-form-item label="开户行">
                                    <span>{{reimbursement.account_bank}}</span>
                                </el-form-item>
                            </div>
                        </fieldset>
                        <common-upload-component
                            ref="upload"
                            :ffileList="reimbursement.other"
                            upload_url="reimbursement/upload"
                        ></common-upload-component>
                    </div>
                    <div v-else>
                        <fieldset  class="fieldsetc">
                            <legend class="legendc">基础信息</legend>
                            <el-descriptions
                                class="margin-top"
                                :column="2"
                                :labelStyle="{'width':'120px','text-align':'center'}"
                                :contentStyle="{'text-align': 'center'}"
                                border
                            >
                                <el-descriptions-item :span="2">
                                    <template slot="label">
                                        <span>报销标题</span>
                                    </template>
                                    <span>{{reimbursement.title}}</span>
                                </el-descriptions-item>
                                <el-descriptions-item :span="2">
                                    <template slot="label">
                                        <span>申请部门</span>
                                    </template>
                                    <span>{{reimbursement.enterprise_name ? reimbursement.enterprise_name + '/' + reimbursement.department_name : ''}}</span>
                                </el-descriptions-item>
                            </el-descriptions>
                        </fieldset>
                        <fieldset  class="fieldsetc glxx">
                            <legend class="legendc">关联信息</legend>
                            <el-descriptions
                                class="margin-top"
                                :column="3"
                                :labelStyle="{'width':'120px','text-align':'center'}"
                                :contentStyle="{'text-align': 'center'}"
                                border
                            >
                                <el-descriptions-item>
                                    <template slot="label">
                                        <span>采购申请</span>
                                    </template>
                                    <el-link
                                        v-for="purchase_v in reimbursement.with_purchase_obj"
                                        :key="purchase_v.name"
                                        @click="clk_purchase(purchase_v.id)"
                                    >
                                        {{purchase_v.name}}<i class="el-icon-view el-icon--right"></i>
                                    </el-link>
                                </el-descriptions-item>
                                <el-descriptions-item>
                                    <template slot="label">
                                        <span>招待申请</span>
                                    </template>
                                    <el-link
                                        v-for="entertain_v in reimbursement.with_entertain_obj"
                                        :key="entertain_v.name"
                                        @click="clk_entertain(entertain_v.id)"
                                    >
                                        {{entertain_v.name}}<i class="el-icon-view el-icon--right"></i>
                                    </el-link>
                                </el-descriptions-item>
                                <el-descriptions-item>
                                    <template slot="label">
                                        <span>差旅申请</span>
                                    </template>
                                    <el-link
                                        v-for="travel_v in reimbursement.with_travel_obj"
                                        :key="travel_v.name"
                                        @click="clk_travel(travel_v.id)"
                                    >
                                        {{travel_v.name}}<i class="el-icon-view el-icon--right"></i>
                                    </el-link>
                                </el-descriptions-item>
                            </el-descriptions>
                        </fieldset>
                        <fieldset  class="fieldsetc topc tabxx">
                            <legend class="legendc">报销信息</legend>
                            <el-table
                                :data="reimbursement.with_category"
                                border
                                highlight-current-row
                                style="width: 100%"
                            >
                                <el-table-column align="center" type="index"> </el-table-column>
                                <el-table-column
                                    align="center"
                                    label="报销类目"
                                >
                                    <template slot-scope="scope">
                                        <span>{{ scope.row['category_name'] }}</span>
                                    </template>
                                </el-table-column>
                                <el-table-column
                                    align="center"
                                    label="金额"
                                >
                                    <template slot-scope="scope">
                                        <span>{{ scope.row['money'] | fomatFloatMoney }}</span>
                                    </template>
                                </el-table-column>
                                <el-table-column
                                    align="center"
                                    label="备注"
                                >
                                    <template slot-scope="scope">
                                        <span>{{ scope.row['note'] }}</span>&nbsp;
                                        <el-link v-if="scope.row['note']" @click="del_note(scope.row['note'])"><i class="el-icon-circle-close el-icon--right"></i></el-link>
                                    </template>
                                </el-table-column>
                            </el-table>
                            <el-descriptions
                                class="margin-top"
                                :column="3"
                                :labelStyle="{'width':'120px','text-align':'center'}"
                                :contentStyle="{'text-align': 'center'}"
                                border
                            >
                                <el-descriptions-item :contentStyle="{'text-align':'center','color':'#2828FF'}">
                                    <template slot="label">
                                        <span>申请报销金额</span>
                                    </template>
                                    <span>{{reimbursement.apply_money | fomatFloatMoney}}</span>
                                </el-descriptions-item>
                                <el-descriptions-item :contentStyle="{'text-align':'center','color':'#FF0000'}">
                                    <template slot="label">
                                        <span>抵扣金额</span>
                                    </template>
                                    <span>{{this.deduction_money | fomatFloatMoney}}</span>
                                </el-descriptions-item>
                                <el-descriptions-item :contentStyle="{'text-align':'center','color':'#00BB00'}">
                                    <template slot="label">
                                        <span>实际报销金额</span>
                                    </template>
                                    <span>{{this.reality_money | fomatFloatMoney}}</span>
                                </el-descriptions-item>
                            </el-descriptions>
                        </fieldset>
                        <fieldset class="fieldsetc dkxx" v-if="reimbursement.with_petty[0].petty_id !== ''">
                            <legend class="legendc">抵扣信息</legend>
                            <el-table
                                :data="reimbursement.with_petty"
                                border
                                highlight-current-row
                                style="width: 100%"
                            >
                                <el-table-column align="center" type="index"> </el-table-column>
                                <el-table-column
                                    align="center"
                                    label="借支单"
                                >
                                    <template slot-scope="scope">
                                        <el-link @click="clk_petty(scope.row['petty_id'])">{{scope.row['petty_name']}}<i class="el-icon-view el-icon--right"></i></el-link>
                                    </template>
                                </el-table-column>
                                <el-table-column
                                    align="center"
                                    label="抵扣金额"
                                >
                                    <template slot-scope="scope">
                                        <span>{{ scope.row['money'] | fomatFloatMoney }}</span>
                                    </template>
                                </el-table-column>
                                <el-table-column
                                    align="center"
                                    label="备注"
                                >
                                    <template slot-scope="scope">
                                        <span>{{ scope.row['note'] }}</span>
                                    </template>
                                </el-table-column>
                            </el-table>
                        </fieldset>
                        <fieldset  class="fieldsetc bottomc">
                            <legend class="legendc">账户信息</legend>
                            <el-descriptions
                                class="margin-top"
                                :column="2"
                                :labelStyle="{'width':'120px','text-align':'center'}"
                                :contentStyle="{'text-align': 'center'}"
                                border
                            >
                                <el-descriptions-item>
                                    <template slot="label">
                                        <span>汇款方式</span>
                                    </template>
                                    <span>{{all_pay_way[reimbursement.pay_way]}}</span>
                                </el-descriptions-item>
                                <el-descriptions-item>
                                    <template slot="label">
                                        <span>收款人</span>
                                    </template>
                                    <span>{{reimbursement.account_name}}</span>
                                </el-descriptions-item>
                                <el-descriptions-item>
                                    <template slot="label">
                                        <span>开户行</span>
                                    </template>
                                    <span>{{reimbursement.account_bank}}</span>
                                </el-descriptions-item>
                                <el-descriptions-item>
                                    <template slot="label">
                                        <span>银行卡号</span>
                                    </template>
                                    <span>{{reimbursement.account_card}}</span>
                                </el-descriptions-item>
                            </el-descriptions>
                        </fieldset>
                        <common-upload-component
                            v-show="reimbursement.other.length"
                            ref="upload"
                            :ffileList="reimbursement.other"
                            upload_url="reimbursement/upload"
                            :disabled="true"
                        ></common-upload-component>
                        <common-upload-component
                            v-show="AddFileDialogVisible"
                            ref="file_upload"
                            :ffileList="file_arr"
                            upload_url="reimbursement/upload"
                            title="补充附件"
                        ></common-upload-component>
                    </div>
                </el-form>
            </div>
            <common-budget-component
                v-if="budget_count.all_quota"
                :budget_obj="budget_count"
            ></common-budget-component>
            <div slot="footer" class="dialog-footer">
                <div v-if="show_type===0">
                    <div v-if="AddFileDialogVisible === false">
                        <el-button
                            v-if="add_file_auth"
                            type="success"
                            plain
                            @click="add_file()"
                            >补充附件</el-button
                        >
                        <el-button
                            v-if="pay_auth"
                            type="primary"
                            round
                            @click="pettyPay('PettyEditForm')"
                            >汇款</el-button
                        >
                        <el-button
                            v-if="to_approval_auth"
                            type="success"
                            plain
                            @click="to_approval('ReimbursementEditForm')"
                            >提交</el-button
                        >
                        <el-button
                            v-if="sel_approval_auth"
                            type="warning"
                            plain
                            @click="sel_approval()"
                            >查看审批</el-button
                        >
                        <el-button
                            v-if="withdraw_auth"
                            type="danger"
                            plain
                            @click="reimbursementWithdraw('ReimbursementEditForm')"
                            >审批撤回</el-button
                        >
                        <el-button
                            v-if="edit_auth"
                            type="primary"
                            plain
                            @click="$emit('show_edit')"
                            >修改</el-button
                        >
                        <el-button
                            v-if="del_auth"
                            type="danger"
                            plain
                            @click="reimbursementDel('ReimbursementEditForm')"
                            >删除</el-button
                        >
                        <el-button
                            plain
                            @click="closeDialog('ReimbursementEditForm')"
                            >关闭</el-button
                        >
                    </div>
                    <div v-else>
                        <el-button
                            type="success"
                            plain
                            @click="submit_add_file()"
                            >确定</el-button
                        >
                        <el-button
                            plain
                            @click="exit_add_file()"
                            >取消</el-button
                        >
                    </div>
                </div>
                <div v-else-if="show_type===1">
                    <el-button
                        type="success"
                        plain
                        @click="submitInsert('ReimbursementEditForm')"
                        >确定</el-button
                    >
                    <el-button
                        type="warning"
                        plain
                        @click="resetForm('ReimbursementEditForm')"
                        >重置</el-button
                    >
                    <el-button
                        plain
                        @click="closeDialog('ReimbursementEditForm')"
                        >关闭</el-button
                    >
                </div>
                <div v-else>
                    <el-button
                        type="success"
                        plain
                        @click="submitEdit('ReimbursementEditForm')"
                        >确定修改</el-button
                    >
                    <el-button
                        plain
                        @click="closeDialog('ReimbursementEditForm')"
                        >取消操作</el-button
                    >
                </div>
                <common-approval-dialog
                    :id="id"
                    :ApprovalDialogVisible="ApprovalDialogVisible"
                    :data="ApprovalData"
                    :current_examine_staff_id="reimbursement.current_examine_staff_id"
                    :ApprovalAuth="ApprovalAuth"
                    :ApprovalRequest="ReimbursementApprovalRequest"
                    @exitApprovalDialog="exitApprovalDialog"
                    @refresh="$emit('frefresh')"
                >
                </common-approval-dialog>
                <reimbursement-print-component
                    :show_print="show_print"
                    :data="reimbursement"
                    :ApprovalData="ApprovalData"
                    :print_with="print_with"
                    @exit_print="show_print = false"
                >
                </reimbursement-print-component>
            </div>
        </el-dialog>
        <purchase-edit-component
            :id="purchase_component.id"
            :dialogFormVisible="purchase_component.dialogFormVisible"
            :purchase_details_data="purchase_component.purchase_details_data"
            @exitPurchaseDialog="purchase_component.dialogFormVisible = false"
        ></purchase-edit-component>
        <entertain-edit-component
            :id="entertain_component.id"
            :dialogFormVisible="entertain_component.dialogFormVisible"
            :entertain_details_data="entertain_component.entertain_details_data"
            @exitEntertainDialog="entertain_component.dialogFormVisible = false"
        ></entertain-edit-component>
        <travel-edit-component
            :id="travel_component.id"
            :dialogFormVisible="travel_component.dialogFormVisible"
            :travel_details_data="travel_component.travel_details_data"
            @exitTravelDialog="travel_component.dialogFormVisible = false"
        ></travel-edit-component>
        <petty-edit-component
            :id="petty_component.id"
            :dialogFormVisible="petty_component.dialogFormVisible"
            :petty_details_data="petty_component.petty_details_data"
            @exitPettyDialog="petty_component.dialogFormVisible = false"
        ></petty-edit-component>
    </div>
</template>

<script>
import CommonUploadComponent from '@/components/common/CommonUploadComponent'
import CommonApprovalDialog from '@/components/common/CommonApprovalDialog'
import ReimbursementPrintComponent from './ReimbursementPrintComponent'
import CommonBudgetComponent from '@/components/common/CommonBudgetComponent'

import { purchase_details_request} from '@/network/finance/purchase.js'
import PurchaseEditComponent from '@/components/finance/purchase/PurchaseEditComponent'

import { entertain_details_request} from '@/network/finance/entertain.js'
import EntertainEditComponent from '@/components/finance/entertain/EntertainEditComponent'

import { travel_details_request} from '@/network/finance/travel.js'
import TravelEditComponent from '@/components/finance/travel/TravelEditComponent'

import { petty_details_request} from '@/network/finance/petty.js'
import PettyEditComponent from '@/components/finance/petty/PettyEditComponent'

import { 
        reimbursement_add_request,
        reimbursement_del_request,
        reimbursement_edit_request,
        reimbursement_to_approval_request,
        reimbursement_sel_approval_request,
        reimbursement_approval_request,
        reimbursement_pay_request,
        reimbursement_withdraw_request,
        reimbursement_sel_print_with_request,
        reimbursement_add_file_request
    } from '@/network/finance/reimbursement.js'
import { bank_card_list_request,purchase_list_request,entertain_list_request,travel_list_request,petty_list_request,reimbursement_category_list_request } from '@/network/list.js'
import { fomatFloat,thousandBitSeparator } from '@/assets/js/common.js'

export default {
    name: 'ReimbursementEditComponent',
    data() {
        return {
            login_loading: false,
            ApprovalDialogVisible: false,
            reimbursement: {
                title: '',
                department_id: [this.$store.state.user.enterprise_id,this.$store.state.user.department_id],
                apply_money: '',
                other: [],
                print: [],
                with_purchase: '',
                with_entertain: '',
                with_travel: '',
                with_petty: [
                    {
                        petty_id:'',
                        petty_name: '',
                        money: '',
                        note: '',
                    }
                ],
                with_category: [
                    {
                        category_id:'',
                        category_name: '',
                        money: '',
                        note: '',
                    }
                ],
                pay_way: '',
                with_bank_card: '',
                account_card: '',
                account_bank: '',
                account_name: ''
            },
            rules: {
                title: [
                    {
                        required: true,
                        message: '请输入报销标题',
                        trigger: 'blur',
                    },
                ],
                money: [
                    {
                        required: true,
                        message: '请输入报销总金额',
                        trigger: 'blur',
                    },
                ],
                department_id: [
                    {
                        required: true,
                        message: '请选择申请部门',
                        trigger: 'blur',
                    },
                ],
                pay_way: [
                    {
                        required: true,
                        message: '请选择汇款方式',
                        trigger: 'blur',
                    },
                ],
                account_card: [
                    {
                        required: true,
                        message: '缺少收款账号',
                        trigger: 'blur',
                    },
                ],
                account_bank: [
                    {
                        required: true,
                        message: '缺少开户银行',
                        trigger: 'blur',
                    },
                ],
                account_name: [
                    {
                        required: true,
                        message: '缺少户主姓名',
                        trigger: 'blur',
                    },
                ],
            },
            Allcard: [],
            AllCategory: [],
            ApprovalData: [],
            ReimbursementApprovalRequest:reimbursement_approval_request,
            all_pay_way: ['公对公','公对私','私对私','私对公'],
            my_purchase: [],
            my_entertain: [],
            my_travel: [],
            my_petty: [],
            purchase_component: {
                id: 0,
                dialogFormVisible: false,
                purchase_details_data: {}
            },
            entertain_component: {
                id: 0,
                dialogFormVisible: false,
                entertain_details_data: {}
            },
            travel_component: {
                id: 0,
                dialogFormVisible: false,
                travel_details_data: {}
            },
            petty_component: {
                id: 0,
                dialogFormVisible: false,
                petty_details_data: {}
            },
            show_print: false,
            print_with: {
                print_purchase:  [],
                print_entertain: [],
                print_travel: [],
                print_petty: []
            },
            AddFileDialogVisible: false,
            //补充附件
            file_arr: []
        }
    },
    computed: {
        dialogFormTitle() {
            if(this.show_type === 0) {
                return this.reimbursement.name ? '报销单：' + this.reimbursement.name : '报销单详情'
            } else if (this.show_type === 1) {
                return '添加报销申请'
            } else {
                return '修改报销申请'
            }
        },
        deduction_money() {
            if(this.reimbursement.with_petty.length === 0) {
                return 0;
            } else {
                return this.reimbursement.with_petty.reduce((s,cur) => {
                    return s + cur.money * 1
                },0)
            }
        },
        reality_money() {
            if(!isNaN(this.reimbursement.apply_money)){
                const a = this.reimbursement.apply_money - this.deduction_money
                return a<0 ? 0 : a
            } else {
                return 0;
            }
        },
    },
    methods: {
        closeDialog(formName) {
            this.reimbursement = {
                title: '',
                department_id: [this.$store.state.user.enterprise_id,this.$store.state.user.department_id],
                apply_money: '',
                other: [],
                print: [],
                with_purchase: '',
                with_entertain: '',
                with_travel: '',
                with_petty: [
                    {
                        petty_id:'',
                        petty_name: '',
                        money: '',
                        note: '',
                    }
                ],
                with_category: [
                    {
                        category_id:'',
                        category_name: '',
                        money: '',
                        note: '',
                    }
                ],
                pay_way: '',
                with_bank_card: '',
                account_card: '',
                account_bank: '',
                account_name: ''
            }
            this.$emit('exitReimbursementDialog')
        },
        submitInsert(formName) {
            this.$refs[formName].validate((valid) => {
                if (!valid) {
                    return
                }
                this.reimbursementInsert(formName)
            })
        },
        reimbursementInsert(formName) {
            this.login_loading = true
            let d = this.reimbursement
            d.department_id = d.department_id[d.department_id.length - 1]
            d.other = this.$refs.upload.fileList
            reimbursement_add_request(d)
                .then((s) => {
                    this.login_loading = false
                    if (s.status === 0) {
                        this.$message.success('添加成功!')
                        this.$emit('exitReimbursementDialog')
                        this.$emit('search')
                        this.$emit('details_row',s.result.id*1)
                    } else {
                        this.$message.error(s.msg)
                    }
                })
                .catch((err) => {
                    this.login_loading = false
                    this.$message.error(err)
                })
        },
        resetForm(formName) {
            this.reimbursement = {
                title: '',
                department_id: [this.$store.state.user.enterprise_id,this.$store.state.user.department_id],
                apply_money: '',
                other: [],
                print: [],
                with_purchase: '',
                with_entertain: '',
                with_travel: '',
                with_petty: [
                    {
                        petty_id:'',
                        petty_name: '',
                        money: '',
                        note: '',
                    }
                ],
                with_category: [
                    {
                        category_id:'',
                        category_name: '',
                        money: '',
                        note: '',
                    }
                ],
                pay_way: '',
                with_bank_card: '',
                account_card: '',
                account_bank: '',
                account_name: ''
            }
        },
        submitEdit(formName) {
            this.$refs[formName].validate((valid) => {
                if (!valid) {
                    return
                }
                this.reimbursementEdit(formName)
            })
        },
        reimbursementEdit(formName) {
            this.login_loading = true
            this.reimbursement.id = this.id
            this.reimbursement.department_id = this.reimbursement.department_id[this.reimbursement.department_id.length - 1]
            this.reimbursement.other = this.$refs.upload.fileList
            reimbursement_edit_request(this.reimbursement)
                .then((s) => {
                    this.login_loading = false
                    if (s.status === 0) {
                        this.$message.success('修改成功!')
                        this.$emit('exitReimbursementDialog')
                        this.$emit('search')
                        this.$emit('details_row',this.reimbursement.id)
                    } else {
                        this.$message.error(s.msg)
                    }
                })
                .catch((err) => {
                    this.login_loading = false
                    this.$message.error(err)
                })
        },
        reimbursementDel(formName) {
            this.$confirm(
                '是否确定删除？（一经删除，将无法再进行恢复，请谨慎操作！）',
                '温馨提示',
                {
                    confirmButtonText: '确定',
                    cancelButtonText: '取消',
                    type: 'warning',
                }
            )
                .then(() => {
                    reimbursement_del_request(this.id)
                        .then((s) => {
                            if (s.status === 0) {
                                this.$message.success('删除成功!')
                                this.$emit('search')
                            } else {
                                this.$message.error(s.msg)
                            }
                        })
                        .catch((err) => {
                            this.$message.error(err)
                        })
                        .finally(() => this.closeDialog(formName))
                })
                .catch((err) => {})
        },
        pettyPay(formName) {
            this.$confirm(
                '是否确定已经汇款？（一经确认，将无法进行撤销，请谨慎操作！）',
                '温馨提示',
                {
                    confirmButtonText: '确定',
                    cancelButtonText: '取消',
                    type: 'warning',
                }
            )
                .then(() => {
                    reimbursement_pay_request(this.id)
                        .then((s) => {
                            if (s.status === 0) {
                                this.$message.success('已确认汇款!')
                                this.$emit('search')
                            } else {
                                this.$message.error(s.msg)
                            }
                        })
                        .catch((err) => {
                            this.$message.error(err)
                        })
                        .finally(() => this.closeDialog(formName))
                })
                .catch((err) => {})
        },
        get_card_list() {
            bank_card_list_request()
                .then((s) => {
                    this.Allcard = s.result
                })
                .catch((err) => {
                    console.log(err)
                })
        },
        get_category_list() {
            reimbursement_category_list_request()
                .then((s) => {
                    this.AllCategory = s.result
                })
                .catch((err) => {
                    console.log(err)
                })
        },
        to_approval(formName) {
            this.$prompt('请填写申请原因', '提示', {
                    confirmButtonText: '确定',
                    cancelButtonText: '取消',
                }).then(({ value }) => {
                    this.login_loading = true
                    reimbursement_to_approval_request({id:this.id,note:value})
                        .then((s) => {
                            this.login_loading = false
                            if (s.status === 0) {
                                    this.$message.success('提交成功!')
                                    this.$emit('search')
                            } else {
                                this.$message.error(s.msg)
                            }
                        })
                        .catch((err) => {
                            this.login_loading = false
                            this.$message.error(err)
                        })
                        .finally(() => this.closeDialog(formName))
                }).catch(()=>{});
        },
        exitApprovalDialog() {
            this.ApprovalDialogVisible = false
        },
        sel_approval() {
            this.login_loading = true
            reimbursement_sel_approval_request(this.id)
                .then((s) => {
                    this.login_loading = false
                    if (s.status === 0) {
                        this.ApprovalData = s.result
                        this.ApprovalDialogVisible = true
                    } else {
                        this.$message.error(s.msg)
                    }
                })
                .catch((err) => {
                    this.login_loading = false
                    this.$message.error(err)
                })
        },
        cardChange(e) {
            let account_name = ''
            let account_bank = ''
            let account_card = ''
            this.Allcard.forEach(v => {
                if(v.id === e) {
                    account_name = v.user_name
                    account_bank = v.bank
                    account_card = v.card
                }
            });

            this.reimbursement.account_name = account_name
            this.reimbursement.account_bank = account_bank
            this.reimbursement.account_card = account_card
        },
        AddReimbursementPetty(item,v) {
            const index = item.indexOf(v)
            item.splice(index + 1,0,{
                petty_id:'',
                money: '',
                note: '',
                key: Date.now()
            })
        },
        DelReimbursementPetty(item,v) {
            const index = item.indexOf(v)
            if (index !== -1) {
                item.splice(index, 1)
            }
        },
        AddReimbursementCategory(item,v) {
            const index = item.indexOf(v)
            item.splice(index + 1,0,{
                category_id:'',
                money: '',
                note: '',
                key: Date.now()
            })
        },
        DelReimbursementCategory(item,v) {
            const index = item.indexOf(v)
            if (index !== -1) {
                item.splice(index, 1)
            }
        },
        get_with_list() {
            purchase_list_request()
                .then((s) => {
                    this.my_purchase = s.result
                })
                .catch((err) => {
                    console.log(err)
                })
            entertain_list_request()
                .then((s) => {
                    this.my_entertain = s.result
                })
                .catch((err) => {
                    console.log(err)
                })
            travel_list_request()
                .then((s) => {
                    this.my_travel = s.result
                })
                .catch((err) => {
                    console.log(err)
                })
            petty_list_request()
                .then((s) => {
                    this.my_petty = s.result
                })
                .catch((err) => {
                    console.log(err)
                })
        },
        WithPettyChange(e,value) {
            if(e === '') {
                value.money = ''
                value.note = ''
            } else {
                this.my_petty.forEach(v => {
                    if(v.id === e) {
                        value.money = v.surplus_money
                    }
                });
            }
        },
        clk_petty(petty_id) {
            this.petty_component.id = petty_id
            this.petty_component.dialogFormVisible = true
            petty_details_request(petty_id)
                .then((s) => {
                    if (s.status === 0) {
                        this.petty_component.petty_details_data = s.result
                    } else {
                        this.$message.error(s.msg)
                    }
                })
                .catch((err) => {
                    console.log(err)
                })
        },
        clk_purchase(purchase_id) {
            this.purchase_component.id = purchase_id
            this.purchase_component.dialogFormVisible = true
            purchase_details_request(purchase_id)
                .then((s) => {
                    if (s.status === 0) {
                        this.purchase_component.purchase_details_data = s.result
                    } else {
                        this.$message.error(s.msg)
                    }
                })
                .catch((err) => {
                    console.log(err)
                })
        },
        clk_entertain(entertain_id) {
            this.entertain_component.id = entertain_id
            this.entertain_component.dialogFormVisible = true
            entertain_details_request(entertain_id)
                .then((s) => {
                    if (s.status === 0) {
                        this.entertain_component.entertain_details_data = s.result
                    } else {
                        this.$message.error(s.msg)
                    }
                })
                .catch((err) => {
                    console.log(err)
                })
        },
        clk_travel(travel_id) {
            this.travel_component.id = travel_id
            this.travel_component.dialogFormVisible = true
            travel_details_request(travel_id)
                .then((s) => {
                    if (s.status === 0) {
                        this.travel_component.travel_details_data = s.result
                    } else {
                        this.$message.error(s.msg)
                    }
                })
                .catch((err) => {
                    console.log(err)
                })
        },
        reimbursementWithdraw() {
            this.$confirm(
                '是否确定撤回？（请谨慎操作）',
                '温馨提示',
                {
                    confirmButtonText: '确定',
                    cancelButtonText: '取消',
                    type: 'warning',
                }
            )
                .then(() => {
                    this.login_loading = true
                    reimbursement_withdraw_request({id:this.id})
                        .then((s) => {
                            this.login_loading = false
                            if (s.status === 0) {
                                this.$message.success('已撤回!')
                                this.$emit('frefresh')
                                this.$emit('search')
                            } else {
                                this.$message.error(s.msg)
                            }
                        })
                        .catch((err) => {
                            this.login_loading = false
                            this.$message.error(err)
                        })
                })
                .catch((err) => {})
        },
        clk_print() {
            this.login_loading = true
            reimbursement_sel_approval_request(this.id)
                .then((s) => {
                    this.login_loading = false
                    if (s.status === 0) {
                        this.ApprovalData = s.result
                    } else {
                        this.$message.error(s.msg)
                    }
                })
                .catch((err) => {
                    this.login_loading = false
                    this.$message.error(err)
                })
            //关联打印
            // reimbursement_sel_print_with_request(this.id)
            //     .then((s) => {
            //         this.login_loading = false
            //         if (s.status === 0) {
            //             this.print_with = s.result
            //         } else {
            //             this.$message.error(s.msg)
            //         }
            //     })
            //     .catch((err) => {
            //         this.login_loading = false
            //         this.$message.error(err)
            //     })
            this.show_print = true
        },
        apply_money() {
            this.reimbursement.apply_money = this.reimbursement.with_category.reduce((s,cur) => {
                let m = 0
                if(cur.money) {
                    m = cur.money * 1
                }
                return s + m
            },0)
        },
        add_file() {
            this.AddFileDialogVisible = true
        },
        exit_add_file() {
            this.AddFileDialogVisible = false
            this.file_arr = []
        },
        submit_add_file() {
            let data = {}
            this.file_arr = this.$refs.file_upload.fileList
            data['id'] = this.id
            data['file'] = this.file_arr
            reimbursement_add_file_request(data)
                .then((s) => {
                    this.login_loading = false
                    if (s.status === 0) {
                        this.$message.success('补充附件成功!')
                        this.$emit('frefresh')
                        this.AddFileDialogVisible = false
                        this.file_arr = []
                    } else {
                        this.$message.error(s.msg)
                    }
                })
                .catch((err) => {
                    this.login_loading = false
                    this.$message.error(err)
                })
        },
        del_note(note) {
            this.reimbursement.with_category.map(v=>{
                if(v.note === note) {
                    v.note = ''
                }
            })
        }
    },
    filters: {
        deduction_money_filter(v) {
            return '- ' + thousandBitSeparator(fomatFloat(v)) + '元';
        },
        reality_money_filter(v) {
            return thousandBitSeparator(fomatFloat(v)) + '元';
        },
        fomatFloatMoney(v) {
            return v === '' ?  '0.00' : thousandBitSeparator(fomatFloat(v)) + '元';
        },
    },
    props: {
        dialogFormVisible: Boolean,
        id: {
            type: Number,
            default() {
                return 0
            },
        },
        reimbursement_details_data: {
            type: Object,
            default() {
                return {}
            },
        },
        enterprise_department: {
            type: Array,
            default() {
                return []
            },
        },
        show_type: {
            type:Number,
            default() {
                return 0
            },
        },
        edit_auth: {
            type:Boolean,
            default() {
                return false
            }
        },
        del_auth: {
            type:Boolean,
            default() {
                return false
            }
        },
        add_file_auth: {
            type:Boolean,
            default() {
                return false
            }
        },
        to_approval_auth: {
            type:Boolean,
            default() {
                return false
            }
        },
        sel_approval_auth: {
            type:Boolean,
            default() {
                return false
            }
        },
        ApprovalAuth: {
            type:Boolean,
            default() {
                return false
            }
        },
        pay_auth: {
            type:Boolean,
            default() {
                return false
            }
        },
        withdraw_auth: {
            type:Boolean,
            default() {
                return false
            }
        },
        print_auth: String,
        budget_count: {
            type: Object,
            default() {
                return {
                    all_quota: 0,
                    use_quota: 0,
                    per_quota: 0,
                    entertain_quota: 0,
                    travel_quota: 0,
                    other_quota: 0
                }
            }
        }
    },
    created() {
        this.get_card_list()
        this.get_with_list()
        this.get_category_list()
    },
    destoryed() {},
    mounted() {},
    updated() {},
    components: {
        CommonUploadComponent,
        CommonApprovalDialog,
        PurchaseEditComponent,
        EntertainEditComponent,
        TravelEditComponent,
        PettyEditComponent,
        ReimbursementPrintComponent,
        CommonBudgetComponent
    },
    watch: {
        reimbursement_details_data(newVal) {
            this.reimbursement = newVal
            this.reimbursement.id = this.id
        },
    },
}
</script>

<style lang='less'>
.bottomc {
    margin-bottom: 10px;
}
.with_bank_cardc {
    width: 100%;
}
.pay_wayc {
    width: 100%;
}
.with_purchasec {
    width: 100%;
}
.with_entertainc {
    width: 100%;
}
.with_travelc {
    width: 100%;
}
.topc {
    margin-top: 26px;
}
.bxmx textarea {
    height: 130px;
}
.row_title{
    text-align: center;
    color: cornflowerblue;
}
.row_content{
    text-align: center;
    height: 32px;
    line-height: 32px;
}
.petty_idc {
    width: 100%;
}
.dkxx .el-link.el-link--default{
    color: #FF00FF;
}
.glxx .el-link.el-link--default{
    color: #FF00FF;
    margin-right: 10px;
}
.tabxx .el-link.el-link--default{
    color: #8600FF;
}
</style>