<template>
    <div>
        <el-dialog
            :visible.sync="show_print"
            :append-to-body="true"
            width="60%"
            :center="true"
            top="10vh"
            :close-on-click-modal="false"
            :before-close="() => $emit('exit_print')"
        >
            <div ref="PrintReimbursement">
                <p style="text-align: center;">
                    <b>
                        <span style="font-size:18px;">{{data | reimbursement_title_name}}</span>
                    </b>
                </p>
                <!-- 报销单 -->
                <table style="font-family: Microsoft YaHei;font-size:15px;width: 100%;border: 1px solid black;">
                    <tr style="border: 1px solid black;">
                        <td style="width: 15%;border: 1px solid black;text-align: center;font-weight:bold;">申请部门</td>
                        <td style="width: 52%;border: 1px solid black;text-align: center;" colspan=3>{{data.enterprise_name+'/'+data.department_name}}</td>
                        <td style="width: 15%;border: 1px solid black;text-align: center;font-weight:bold;">申请人</td>
                        <td style="width: 18%;border: 1px solid black;text-align: center;">{{data.staff_name}}</td>
                    </tr>
                    <tr style="border: 1px solid black;">
                        <td style="border: 1px solid black;text-align: center;font-weight:bold;">报销标题</td>
                        <td style="border: 1px solid black;text-align: center;" colspan=5>{{data.title}}</td>
                    </tr>
                    <tr style="border: 1px solid black;" v-if="data.with_purchase.length > 0">
                        <td style="border: 1px solid black;text-align: center;font-weight:bold;">关联采购单</td>
                        <td style="border: 1px solid black;text-align: center;" colspan=5>{{data.with_purchase_obj | guanlian}}</td>
                    </tr>
                    <tr style="border: 1px solid black;" v-if="data.with_entertain.length > 0">
                        <td style="border: 1px solid black;text-align: center;font-weight:bold;">关联招待单</td>
                        <td style="border: 1px solid black;text-align: center;" colspan=5>{{data.with_entertain_obj | guanlian}}</td>
                    </tr>
                    <tr style="border: 1px solid black;" v-if="data.with_travel.length > 0">
                        <td style="border: 1px solid black;text-align: center;font-weight:bold;">关联差旅单</td>
                        <td style="border: 1px solid black;text-align: center;" colspan=5>{{data.with_travel_obj | guanlian}}</td>
                    </tr>
                    <tr style="border: 1px solid black;" v-if="data.with_petty[0].petty_id !== ''">
                        <td style="border: 1px solid black;text-align: center;font-weight:bold;">关联备用金</td>
                        <td style="border: 1px solid black;text-align: center;" colspan=5>{{data.with_petty | guanlianbyz}}</td>
                    </tr>
                    <tr style="border: 1px solid black;">
                        <td style="border: 1px solid black;text-align: center;font-weight:bold;">报销明细</td>
                        <td style="border: 1px solid black;text-align: left;" colspan=5>
                            <div v-for="with_category_iteam in data.with_category" :key="with_category_iteam.name">
                                {{with_category_iteam | guanlianbx}}
                            </div>
                        </td>
                    </tr>
                    <tr style="border: 1px solid black;">
                        <td style="width: 15%;border: 1px solid black;text-align: center;font-weight:bold;">申请报销金额</td>
                        <td style="width: 19%;border: 1px solid black;text-align: center;">{{data.apply_money | diy_money}}</td>
                        <td style="width: 15%;border: 1px solid black;text-align: center;font-weight:bold;">抵扣金额</td>
                        <td style="width: 18%;border: 1px solid black;text-align: center;">{{data.deduction_money | deduction_money_filter}}</td>
                        <td style="width: 15%;border: 1px solid black;text-align: center;font-weight:bold;">实际报销金额</td>
                        <td style="width: 18%;border: 1px solid black;text-align: center;">{{data.money | reality_money_filter}}</td>
                    </tr>
                    <tr style="border: 1px solid black;">
                        <td style="width: 15%;border: 1px solid black;text-align: center;font-weight:bold;">汇款方式</td>
                        <td style="width: 19%;border: 1px solid black;text-align: center;">{{all_pay_way[data.pay_way]}}</td>
                        <td style="width: 15%;border: 1px solid black;text-align: center;font-weight:bold;">账户类型</td>
                        <td style="width: 18%;border: 1px solid black;text-align: center;">银行账户</td>
                        <td style="width: 15%;border: 1px solid black;text-align: center;font-weight:bold;">收款人</td>
                        <td style="width: 18%;border: 1px solid black;text-align: center;">{{data.account_name}}</td>
                    </tr>
                    <tr style="border: 1px solid black;">
                        <td style="width: 15%;border: 1px solid black;text-align: center;font-weight:bold;">开户行</td>
                        <td style="width: 52%;border: 1px solid black;text-align: center;" colspan=3>{{data.account_bank}}</td>
                        <td style="width: 15%;border: 1px solid black;text-align: center;font-weight:bold;">银行卡号</td>
                        <td style="width: 18%;border: 1px solid black;text-align: center;">{{data.account_card}}</td>
                    </tr>
                    <tr style="border: 1px solid black;">
                        <td style="border: 1px solid black;text-align: center;font-weight:bold;">审批人</td>
                        <td style="border: 1px solid black;text-align: center;" colspan=5>{{reimbursement_examine}}</td>
                    </tr>
                </table>
                <div v-for="(iteam,index) in data.print" :key="iteam.time" style="font-family: Microsoft YaHei;font-size:15px;">
                    {{'【历史】此报销申请为第'+(index+1)+'次打印，打印人：'+iteam.staff+'，打印时间：'+iteam.time}}
                </div>
                <div style="font-family: Microsoft YaHei;font-size:15px;">
                    {{'【当前】此报销申请为第'+(data.print.length+1)+'次打印，打印人：'+$store.state.user.name+'，打印时间：' + NowTime}}
                </div>
                <!-- 采购单 -->
                <div v-if="data.with_purchase.length > 0">
                    <div v-for="(print_purchase_v,print_purchase_k) in print_with.print_purchase" :key="print_purchase_v.name">
                        <p style="text-align: center;">
                        <b>
                            <span>{{print_purchase_v | purchase_title_name}}</span>
                        </b>
                        </p>
                        <table style="font-family: Microsoft YaHei;font-size:12px;width: 100%;border: 1px solid black;">
                            <tr style="border: 1px solid black;">
                                <td style="width: 15%;border: 1px solid black;text-align: center;font-weight:bold;">申请部门</td>
                                <td style="width: 45%;border: 1px solid black;text-align: center;">{{print_purchase_v.enterprise_name+'/'+print_purchase_v.department_name}}</td>
                                <td style="width: 15%;border: 1px solid black;text-align: center;font-weight:bold;">申请人</td>
                                <td style="width: 25%;border: 1px solid black;text-align: center;">{{print_purchase_v.staff_name}}</td>
                            </tr>
                            <tr style="border: 1px solid black;">
                                <td style="border: 1px solid black;text-align: center;font-weight:bold;">申请金额</td>
                                <td style="border: 1px solid black;text-align: center;">{{print_purchase_v.money | diy_money}}</td>
                                <td style="border: 1px solid black;text-align: center;font-weight:bold;">申请日期</td>
                                <td style="border: 1px solid black;text-align: center;">{{print_purchase_v.apply_date}}</td>
                            </tr>
                            <tr style="border: 1px solid black;">
                                <td style="border: 1px solid black;text-align: center;font-weight:bold;">采购标题</td>
                                <td style="border: 1px solid black;text-align: center;" colspan=3>{{print_purchase_v.title}}</td>
                            </tr>
                            <tr style="border: 1px solid black;">
                                <td style="border: 1px solid black;text-align: center;font-weight:bold;">采购清单</td>
                                <td style="border: 1px solid black;text-align: left;white-space: pre-line;" colspan=3>{{print_purchase_v.content}}</td>
                            </tr>
                            <tr style="border: 1px solid black;">
                                <td style="border: 1px solid black;text-align: center;font-weight:bold;">采购原因</td>
                                <td style="border: 1px solid black;text-align: left;white-space: pre-line;" colspan=3>{{print_purchase_v.cause}}</td>
                            </tr>
                            <tr style="border: 1px solid black;">
                                <td style="border: 1px solid black;text-align: center;font-weight:bold;">审批人</td>
                                <td style="border: 1px solid black;text-align: center;" colspan=3>{{purchase_examine[print_purchase_k]}}</td>
                            </tr>
                        </table>
                    </div>
                </div>
                <!-- 招待单 -->
                <div v-if="data.with_entertain.length > 0">
                    <div v-for="(print_entertain_v,print_entertain_k) in print_with.print_entertain" :key="print_entertain_v.name">
                        <p style="text-align: center;">
                            <b>
                                <span>{{print_entertain_v | entertain_title_name}}</span>
                            </b>
                        </p>
                        <table style="font-family: Microsoft YaHei;font-size:12px;width: 100%;border: 1px solid black;">
                            <tr style="border: 1px solid black;">
                                <td style="width: 15%;border: 1px solid black;text-align: center;font-weight:bold;">申请部门</td>
                                <td style="width: 45%;border: 1px solid black;text-align: center;">{{print_entertain_v.enterprise_name+'/'+print_entertain_v.department_name}}</td>
                                <td style="width: 15%;border: 1px solid black;text-align: center;font-weight:bold;">申请人</td>
                                <td style="width: 25%;border: 1px solid black;text-align: center;">{{print_entertain_v.staff_name}}</td>
                            </tr>
                            <tr style="border: 1px solid black;">
                                <td style="border: 1px solid black;text-align: center;font-weight:bold;">招待标题</td>
                                <td style="border: 1px solid black;text-align: center;" colspan=3>{{print_entertain_v.title}}</td>
                            </tr>
                            <tr style="border: 1px solid black;">
                                <td style="border: 1px solid black;text-align: center;font-weight:bold;">事项简述</td>
                                <td style="border: 1px solid black;text-align: left;white-space: pre-line;" colspan=3>{{print_entertain_v.content}}</td>
                            </tr>
                            <tr style="border: 1px solid black;">
                                <td style="border: 1px solid black;text-align: center;font-weight:bold;">招待日期</td>
                                <td style="border: 1px solid black;text-align: center;" colspan=3>{{print_entertain_v.entertain_date}}</td>
                            </tr>
                            <tr style="border: 1px solid black;">
                                <td style="width: 15%;border: 1px solid black;text-align: center;font-weight:bold;">预计人数</td>
                                <td style="width: 45%;border: 1px solid black;text-align: center;">{{print_entertain_v.client_num+'人'}}</td>
                                <td style="width: 15%;border: 1px solid black;text-align: center;font-weight:bold;">预计花费</td>
                                <td style="width: 25%;border: 1px solid black;text-align: center;">{{print_entertain_v.money | diy_money}}</td>
                            </tr>
                            <tr style="border: 1px solid black;">
                                <td style="border: 1px solid black;text-align: center;font-weight:bold;">物资需求</td>
                                <td style="border: 1px solid black;text-align: center;" colspan=3>{{print_entertain_v.goods}}</td>
                            </tr>
                            <tr style="border: 1px solid black;">
                                <td style="border: 1px solid black;text-align: center;font-weight:bold;">备注</td>
                                <td style="border: 1px solid black;text-align: center;" colspan=3>{{print_entertain_v.note}}</td>
                            </tr>
                            <tr style="border: 1px solid black;">
                                <td style="border: 1px solid black;text-align: center;font-weight:bold;">审批人</td>
                                <td style="border: 1px solid black;text-align: center;" colspan=3>{{entertain_examine[print_entertain_k]}}</td>
                            </tr>
                        </table>
                    </div>
                </div>
                <!-- 差旅单 -->
                <div v-if="data.with_travel.length > 0">
                    <div v-for="(print_travel_v,print_travel_k) in print_with.print_travel" :key="print_travel_v.name">
                        <p style="text-align: center;">
                            <b>
                                <span>{{print_travel_v | travel_title_name}}</span>
                            </b>
                        </p>
                        <table style="font-family: Microsoft YaHei;font-size:12px;width: 100%;border: 1px solid black;">
                            <tr style="border: 1px solid black;">
                                <td style="width: 15%;border: 1px solid black;text-align: center;font-weight:bold;">申请部门</td>
                                <td style="width: 45%;border: 1px solid black;text-align: center;">{{print_travel_v.enterprise_name+'/'+data.department_name}}</td>
                                <td style="width: 15%;border: 1px solid black;text-align: center;font-weight:bold;">申请人</td>
                                <td style="width: 25%;border: 1px solid black;text-align: center;">{{print_travel_v.staff_name}}</td>
                            </tr>
                            <tr style="border: 1px solid black;">
                                <td style="border: 1px solid black;text-align: center;font-weight:bold;">随行人员</td>
                                <td style="border: 1px solid black;text-align: center;" colspan=3>{{print_travel_v.entourage_name}}</td>
                            </tr>
                            <tr style="border: 1px solid black;">
                                <td style="border: 1px solid black;text-align: center;font-weight:bold;">差旅标题</td>
                                <td style="border: 1px solid black;text-align: center;" colspan=3>{{print_travel_v.title}}</td>
                            </tr>
                            <tr style="border: 1px solid black;">
                                <td style="border: 1px solid black;text-align: center;font-weight:bold;">差旅原由</td>
                                <td style="border: 1px solid black;text-align: left;white-space: pre-line;" colspan=3>{{print_travel_v.content}}</td>
                            </tr>
                            <tr style="border: 1px solid black;">
                                <td style="border: 1px solid black;text-align: center;font-weight:bold;">行程安排</td>
                                <td style="border: 1px solid black;text-align: left;white-space: pre-line;" colspan=3>{{print_travel_v.trip}}</td>
                            </tr>
                            <tr style="border: 1px solid black;">
                                <td style="width: 15%;border: 1px solid black;text-align: center;font-weight:bold;">目的地</td>
                                <td style="width: 45%;border: 1px solid black;text-align: center;">{{print_travel_v.address}}</td>
                                <td style="width: 15%;border: 1px solid black;text-align: center;font-weight:bold;">差旅时长</td>
                                <td style="width: 25%;border: 1px solid black;text-align: center;">{{print_travel_v.days+'天'}}</td>
                            </tr>
                            <tr style="border: 1px solid black;">
                                <td style="width: 15%;border: 1px solid black;text-align: center;font-weight:bold;">开始日期</td>
                                <td style="width: 45%;border: 1px solid black;text-align: center;">{{print_travel_v.start_time}}</td>
                                <td style="width: 15%;border: 1px solid black;text-align: center;font-weight:bold;">结束日期</td>
                                <td style="width: 25%;border: 1px solid black;text-align: center;">{{print_travel_v.end_time}}</td>
                            </tr>
                            <tr style="border: 1px solid black;">
                                <td style="width: 15%;border: 1px solid black;text-align: center;font-weight:bold;">差旅预算</td>
                                <td style="width: 45%;border: 1px solid black;text-align: center;">{{print_travel_v.travel_money | diy_money}}</td>
                                <td style="width: 15%;border: 1px solid black;text-align: center;font-weight:bold;">是否需要招待</td>
                                <td style="width: 25%;border: 1px solid black;text-align: center;">{{print_travel_v.contain_entertain ? '需要' : '不需要'}}</td>
                            </tr>
                            <tr style="border: 1px solid black;" v-if="print_travel_v.contain_entertain">
                                <td style="border: 1px solid black;text-align: center;font-weight:bold;">招待目的</td>
                                <td style="border: 1px solid black;text-align: left;white-space: pre-line;" colspan=3>{{print_travel_v.entertain_content}}</td>
                            </tr>
                            <tr style="border: 1px solid black;" v-if="print_travel_v.contain_entertain">
                                <td style="border: 1px solid black;text-align: center;font-weight:bold;">物资需求</td>
                                <td style="border: 1px solid black;text-align: left;white-space: pre-line;" colspan=3>{{print_travel_v.entertain_goods}}</td>
                            </tr>
                            <tr style="border: 1px solid black;" v-if="print_travel_v.contain_entertain">
                                <td style="border: 1px solid black;text-align: center;font-weight:bold;">招待日期</td>
                                <td style="border: 1px solid black;text-align: center;" colspan=3>{{print_travel_v.entertain_date}}</td>
                            </tr>
                            <tr style="border: 1px solid black;" v-if="print_travel_v.contain_entertain">
                                <td style="width: 15%;border: 1px solid black;text-align: center;font-weight:bold;">招待预算</td>
                                <td style="width: 25%;border: 1px solid black;text-align: center;">{{print_travel_v.entertain_money | diy_money}}</td>
                                <td style="width: 15%;border: 1px solid black;text-align: center;font-weight:bold;">预计人数</td>
                                <td style="width: 45%;border: 1px solid black;text-align: center;">{{print_travel_v.entertain_client_num + '人'}}</td>
                            </tr>
                            <tr style="border: 1px solid black;">
                                <td style="border: 1px solid black;text-align: center;font-weight:bold;">费用事项</td>
                                <td style="border: 1px solid black;text-align: left;white-space: pre-line;" colspan=3>{{print_travel_v.money_note}}</td>
                            </tr>
                            <tr style="border: 1px solid black;">
                                <td style="border: 1px solid black;text-align: center;font-weight:bold;">预算总金额</td>
                                <td style="border: 1px solid black;text-align: center;" colspan=3>{{print_travel_v.money | diy_money}}</td>
                            </tr>
                            <tr style="border: 1px solid black;">
                                <td style="border: 1px solid black;text-align: center;font-weight:bold;">审批人</td>
                                <td style="border: 1px solid black;text-align: center;" colspan=3>{{travel_examine[print_travel_k]}}</td>
                            </tr>
                        </table>
                    </div>
                </div>
                <!-- 备用金 -->
                <div v-if="data.with_petty[0].petty_id !== ''">
                    <div v-for="(print_petty_v,print_petty_k) in print_with.print_petty" :key="print_petty_v.name">
                        <p style="text-align: center;">
                            <b>
                                <span>{{print_petty_v | petty_title_name}}</span>
                            </b>
                        </p>
                        <table style="font-family: Microsoft YaHei;font-size:12px;width: 100%;border: 1px solid black;">
                            <tr style="border: 1px solid black;">
                                <td style="width: 15%;border: 1px solid black;text-align: center;font-weight:bold;">申请部门</td>
                                <td style="width: 45%;border: 1px solid black;text-align: center;">{{print_petty_v.enterprise_name+'/'+data.department_name}}</td>
                                <td style="width: 15%;border: 1px solid black;text-align: center;font-weight:bold;">申请人</td>
                                <td style="width: 25%;border: 1px solid black;text-align: center;">{{print_petty_v.staff_name}}</td>
                            </tr>
                            <tr style="border: 1px solid black;">
                                <td style="border: 1px solid black;text-align: center;font-weight:bold;">借支标题</td>
                                <td style="border: 1px solid black;text-align: center;" colspan=3>{{print_petty_v.title}}</td>
                            </tr>
                            <tr style="border: 1px solid black;">
                                <td style="border: 1px solid black;text-align: center;font-weight:bold;">申请原由</td>
                                <td style="border: 1px solid black;text-align: left;white-space: pre-line;" colspan=3>{{print_petty_v.content}}</td>
                            </tr>
                            <tr style="border: 1px solid black;">
                                <td style="width: 15%;border: 1px solid black;text-align: center;font-weight:bold;">申请类型</td>
                                <td style="width: 45%;border: 1px solid black;text-align: center;">{{all_petty_type[print_petty_v.type]}}</td>
                                <td style="width: 15%;border: 1px solid black;text-align: center;font-weight:bold;">申请金额</td>
                                <td style="width: 25%;border: 1px solid black;text-align: center;">{{print_petty_v.money | diy_money}}</td>
                            </tr>
                            <tr style="border: 1px solid black;">
                                <td style="width: 15%;border: 1px solid black;text-align: center;font-weight:bold;">已用金额</td>
                                <td style="width: 45%;border: 1px solid black;text-align: center;">{{print_petty_v.use_money | diy_money}}</td>
                                <td style="width: 15%;border: 1px solid black;text-align: center;font-weight:bold;">剩余金额</td>
                                <td style="width: 25%;border: 1px solid black;text-align: center;">{{(print_petty_v.money-print_petty_v.use_money) | diy_money}}</td>
                            </tr>
                            <tr style="border: 1px solid black;">
                                <td style="width: 15%;border: 1px solid black;text-align: center;font-weight:bold;">汇款方式</td>
                                <td style="width: 45%;border: 1px solid black;text-align: center;">{{all_pay_way[print_petty_v.pay_way]}}</td>
                                <td style="width: 15%;border: 1px solid black;text-align: center;font-weight:bold;">收款人</td>
                                <td style="width: 25%;border: 1px solid black;text-align: center;">{{print_petty_v.account_name}}</td>
                            </tr>
                            <tr style="border: 1px solid black;">
                                <td style="width: 15%;border: 1px solid black;text-align: center;font-weight:bold;">开户行</td>
                                <td style="width: 45%;border: 1px solid black;text-align: center;">{{print_petty_v.account_bank}}</td>
                                <td style="width: 15%;border: 1px solid black;text-align: center;font-weight:bold;">银行卡号</td>
                                <td style="width: 25%;border: 1px solid black;text-align: center;">{{print_petty_v.account_card}}</td>
                            </tr>
                            <tr style="border: 1px solid black;">
                                <td style="border: 1px solid black;text-align: center;font-weight:bold;">审批人</td>
                                <td style="border: 1px solid black;text-align: center;" colspan=3>{{petty_examine[print_petty_k]}}</td>
                            </tr>
                        </table>
                    </div>
                </div>
                <br/>
            </div>
            <div slot="footer" class="dialog-footer">
                <el-button
                    plain
                    type="primary"
                    @click="printBtn('PrintReimbursement')"
                    >打印</el-button
                >
                <el-button
                    plain
                    @click="() => $emit('exit_print')"
                    >关闭</el-button
                >
            </div>
        </el-dialog>
    </div>
</template>

<script>
import { fomatFloat,thousandBitSeparator,now } from '@/assets/js/common.js'
import { reimbursement_print_request } from '@/network/finance/reimbursement.js'

export default {
    name:'ReimbursementPrintComponent',
    data(){
        return {
            NowTime: '',
            reimbursement_examine: '',
            purchase_examine: [],
            entertain_examine: [],
            travel_examine: [],
            petty_examine: [],
            all_pay_way: ['公对公','公对私','私对私','私对公'],
            all_petty_type: ['提前借支','储备额度','保证金']
        }
    },
    computed:{
        deduction_money() {
            if(this.data.with_petty.length === 0) {
                return 0;
            } else {
                return this.data.with_petty.reduce((s,cur) => {
                    return s + cur.money * 1
                },0)
            }
        },
        reality_money() {
            if(!isNaN(this.data.money)){
                const a = this.data.money - this.deduction_money
                return a<0 ? 0 : a
            } else {
                return 0;
            }
        }
    },
    methods:{
        printBtn(formRef) {
            this.login_loading = true
            reimbursement_print_request(this.data.id)
                .then((s) => {
                    this.login_loading = false
                    if (s.status === 0) {
                        this.$print(this.$refs[formRef])
                    } else {
                        this.$message.error(s.msg)
                    }
                })
                .catch((err) => {
                    this.login_loading = false
                    this.$message.error(err)
                })
        },
    },
    filters:{
        diy_money(v) {
            return '￥'+ thousandBitSeparator(fomatFloat(v))
        },
        reimbursement_title_name(v) {
            let a = '报销单：'+v.name+' '

            if(v.state === 0) {
                a += '【待提交】'
            } else if(v.state === 1) {
                a += '【审批中】'
            } else if(v.state === 2) {
                a += '【已通过】'
            } else if(v.state === 3) {
                a += '【已拒绝】'
            }

            if(v.pay_state === 0) {
                a += '【未汇款】'
            } else if(v.pay_state === 1) {
                a += '【已汇款】'
            } else if(v.pay_state === 2) {
                a += '【无需汇款】'
            }

            return a
        },
        purchase_title_name(v) {
            let a = '关联采购单：'+v.name+' '

            if(v.state === 0) {
                a += '【待提交】'
            } else if(v.state === 1) {
                a += '【审批中】'
            } else if(v.state === 2) {
                a += '【已通过】'
            } else if(v.state === 3) {
                a += '【已拒绝】'
            }

            return a
        },
        entertain_title_name(v) {
            let a = '关联招待单：'+v.name+' '

            if(v.state === 0) {
                a += '【待提交】'
            } else if(v.state === 1) {
                a += '【审批中】'
            } else if(v.state === 2) {
                a += '【已通过】'
            } else if(v.state === 3) {
                a += '【已拒绝】'
            }

            return a
        },
        travel_title_name(v) {
            let a = '关联差旅单：'+v.name+' '

            if(v.state === 0) {
                a += '【待提交】'
            } else if(v.state === 1) {
                a += '【审批中】'
            } else if(v.state === 2) {
                a += '【已通过】'
            } else if(v.state === 3) {
                a += '【已拒绝】'
            }

            return a
        },
        petty_title_name(v) {
            let a = '关联备用金：'+v.name+' '

            if(v.state === 0) {
                a += '【待提交】'
            } else if(v.state === 1) {
                a += '【审批中】'
            } else if(v.state === 2) {
                a += '【已通过】'
            } else if(v.state === 3) {
                a += '【已拒绝】'
            }

            if(v.pay_state === 0) {
                a += '【未汇款】'
            } else if(v.pay_state === 1) {
                a += '【已汇款】'
            }

            return a
        },
        deduction_money_filter(v) {
            return '￥' + thousandBitSeparator(fomatFloat(v));
        },
        reality_money_filter(v) {
            return '￥' + thousandBitSeparator(fomatFloat(v));
        },
        guanlian(v) {
            if(v) {
                let a=''
                v.forEach(e => {
                    if(a === '') {
                        a = e.name
                    } else {
                        a += ',' + e.name
                    }
                })
                return a
            }
            
        },
        guanlianbyz(v) {
            let a = ''
            v.forEach(e => {
                if(a === '') {
                    a = e.petty_name + '【抵扣￥' + thousandBitSeparator(fomatFloat(e.money)) + '】'
                } else {
                    a += ',' + e.petty_name + '【抵扣￥' + thousandBitSeparator(fomatFloat(e.money)) + '】'
                }
            })
            return a
        },
        guanlianbx(v) {
            return '【' + v.category_name + '】￥' + thousandBitSeparator(fomatFloat(v.money)) + '，备注：' + (v.note === '' ? '无' : v.note)
        }
    },
    props:{
        show_print: {
            type: Boolean,
            default() {
                return false
            }
        },
        data: {
            type: Object,
            default() {
                return {}
            }
        },
        ApprovalData: {
            type: Array,
            default() {
                return []
            }
        },
        print_with: {
            type: Object,
            default() {
                return {
                    print_purchase:  [],
                    print_entertain: [],
                    print_travel: [],
                    print_petty: []
                }
            }
        }
    },
    created(){},
    destoryed(){},
    mounted(){},
    updated(){
        this.NowTime = now()
    },
    components:{},
    watch:{
        ApprovalData(newVal) {
            this.reimbursement_examine = ''
            newVal.forEach(v => {
                if(v.start_staff_id) {
                    this.reimbursement_examine = v.start_staff_name+'(发起)'
                }
                if(v.staff_id === -1) {
                    this.reimbursement_examine += '→'+v.staff_name+'(撤回)'
                }
                if((v.staff_id>0) && (!v.time)) {
                    this.reimbursement_examine += '→'+v.staff_name+'(待审批)'
                }
                if(v.opinion) {
                    this.reimbursement_examine += '→'+v.staff_name+'('+v.opinion+')'
                }
                if(v.staff_id === 0) {
                    this.reimbursement_examine += '→流程结束'
                }
            })
        },
        print_with(newVal) {
            this.purchase_examine = []
            this.entertain_examine = []
            this.travel_examine = []
            this.petty_examine = []
            let examine_string = ''
            //采购
            newVal.print_purchase.forEach((v) => {
                examine_string = ''
                v.examine_record.forEach(e => {
                    if(e.start_staff_id) {
                        examine_string = e.start_staff_name+'(发起)'
                    }
                    if(e.staff_id === -1) {
                        examine_string += '→'+e.staff_name+'(撤回)'
                    }
                    if((e.staff_id>0) && (!e.time)) {
                        examine_string += '→'+v.staff_name+'(待审批)'
                    }
                    if(e.opinion) {
                        examine_string += '→'+e.staff_name+'('+e.opinion+')'
                    }
                    if(e.staff_id === 0) {
                        examine_string += '→流程结束'
                    }
                })
                this.purchase_examine.push(examine_string)
            })
            //招待
            newVal.print_entertain.forEach((v) => {
                examine_string = ''
                v.examine_record.forEach(e => {
                    if(e.start_staff_id) {
                        examine_string = e.start_staff_name+'(发起)'
                    }
                    if(e.staff_id === -1) {
                        examine_string += '→'+e.staff_name+'(撤回)'
                    }
                    if((e.staff_id>0) && (!e.time)) {
                        examine_string += '→'+v.staff_name+'(待审批)'
                    }
                    if(e.opinion) {
                        examine_string += '→'+e.staff_name+'('+e.opinion+')'
                    }
                    if(e.staff_id === 0) {
                        examine_string += '→流程结束'
                    }
                })
                this.entertain_examine.push(examine_string)
            })
            //差旅
            newVal.print_travel.forEach((v) => {
                examine_string = ''
                v.examine_record.forEach(e => {
                    if(e.start_staff_id) {
                        examine_string = e.start_staff_name+'(发起)'
                    }
                    if(e.staff_id === -1) {
                        examine_string += '→'+e.staff_name+'(撤回)'
                    }
                    if((e.staff_id>0) && (!e.time)) {
                        examine_string += '→'+v.staff_name+'(待审批)'
                    }
                    if(e.opinion) {
                        examine_string += '→'+e.staff_name+'('+e.opinion+')'
                    }
                    if(e.staff_id === 0) {
                        examine_string += '→流程结束'
                    }
                })
                this.travel_examine.push(examine_string)
            })
            //备用金
            newVal.print_petty.forEach((v) => {
                examine_string = ''
                v.examine_record.forEach(e => {
                    if(e.start_staff_id) {
                        examine_string = e.start_staff_name+'(发起)'
                    }
                    if(e.staff_id === -1) {
                        examine_string += '→'+e.staff_name+'(撤回)'
                    }
                    if((e.staff_id>0) && (!e.time)) {
                        examine_string += '→'+v.staff_name+'(待审批)'
                    }
                    if(e.opinion) {
                        examine_string += '→'+e.staff_name+'('+e.opinion+')'
                    }
                    if(e.staff_id === 0) {
                        examine_string += '→流程结束'
                    }
                })
                this.petty_examine.push(examine_string)
            })
        }
    }
}
</script>

<style>
</style>